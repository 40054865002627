/* -------------------------------------------------------------- 

BASE STYLES

-------------------------------------------------------------- */
* { 
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html, body {
	font-size: $base + px;
	line-height: 1;
	color: $grey-x-dark;
	background: $white;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}
html { height: 100%; }
body { 
	min-height: 100%;
	-webkit-backface-visibility: hidden;
}

html.loaded, .loaded body {
	color: $grey;
	background: $grey-xx-dark;
}

// body:before {
// 	content: '';
// 	background: $white;
// 	display: block;
// 	position: fixed;
// 	top: 0;
// 	width: 100%;
// 	height: 50%;
// 	z-index: -1;
// }

body.show-grid .page:before {
	content: '';
	background: url('/ui/img/grid-l.png') repeat-y 0 0;
	display: block;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 1403px;
	height: 1000%;
	opacity: .15;
	z-index: 1000;

	@include mq($from: xs) {
		left: 50%;
		margin-left: -701.5px;
	}

}

// body.show-grid .page:before {
// 	background: url('/ui/img/s-view.png') no-repeat 0 0;
// 	width: 900px;
// 	height: 900px;
// 	margin-left: -450px;
// }

.page {
	color: $grey-x-dark;
	background: $white;
	width: 100%;
	overflow: hidden;
}


/* WORD BREAKS AND HYPHENATION + PADDING
-------------------------------------------------------------- */
h1, h2, h3, h4, h5, h6, p, li, dd, cite, blockquote, a, div {
	word-wrap: break-word;
}


/* NO LINE BREAKS
-------------------------------------------------------------- */
.anobr {
	white-space: nowrap;
}

@include mq($from: xs, $to: s) {

	.nobr-xs {
		white-space: nowrap;
	}

}

@include mq($from: m, $to: l) {

	.nobr-m {
		white-space: nowrap;
	}

}

@include mq($from: m) {
	.nobr {
		white-space: nowrap;
	}
}


/* Links
-------------------------------------------------------------- */
a {
	color: inherit;
	text-decoration: underline;

	&:focus {
		color: $blue;
	}

}

/* Images
-------------------------------------------------------------- */
img {
	width: 100%;
	height: auto;
}

.img-wrap {
	background: $white;
	position: relative;
	overflow: hidden;
	margin: 0 auto;

	img {
		transition: all 700ms;
		position: absolute;
		top: 0;
		left: 0;
	}

}

.js img.unveil {
	opacity: 0;

	&.lazyloaded {
		opacity: 1;
	}
	
}
