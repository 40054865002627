/* -------------------------------------------------------------- 

MODULES

-------------------------------------------------------------- */


/* SECTION HEADERS
-------------------------------------------------------------- */
.block--projects .h-section-wrap {
	padding-right: em(23,$base);
	padding-left: em(23,$base);

	@include mq($from: s) {
		background: none;
		max-width: none;
	}

}

.h-section {
	position: relative;
	padding-bottom: em(23,$base);
	padding-left: em(69,$base);

	@include mq($from: s) {
		padding-left: 0;
		margin-bottom: 0;
	}

	&:after {
		@include h-rule-before($grey-x-dark,auto,0,em(12,$base),0);

		@include mq($from: s) {
			display: none;
		}

	}

}

.block--projects .h-section {
	// background: red;
	margin-right: auto;
	margin-bottom: em(-12,$base);
	margin-left: auto;

	@include mq($from: xs) {
		max-width: em(368,$base);
	}

	@include mq($from: s) {
		color: $white;
		width: em(529,$base);
		height: em(230,$base);
		max-width: none;
		z-index: 100;
		padding-left: 0;
		margin-bottom: em(-223,$base);
	}

	@include mq($from: m) {
		width: em(897,$base);
		height: em(253,$base);
		margin-bottom: em(-257,$base);
	}

	@include mq($from: l) {
		width: em(1357,$base);
	}

	&:after {
		display: none;
	}

}


/* SECTION HEADER NUMBERS
-------------------------------------------------------------- */
.h-section__number {
	// background: red;
	position: absolute;
	top: em(-33,$base);
	left: 0;
	width: em(62,$base);

	svg {
		max-width: 100%;

		#a { 
			fill: $grey-x-dark;
		}

	}

	@include mq($from: s) {
		position: static;
		width: em(110,$base);
		margin: em(-8,$base) 0 em(-3,$base);
	}

	@include mq($from: m) {
		width: em(187,$base);
		margin: em(3,$base) 0 em(9,$base);
	}

}

.block--projects .h-section__number {

	@include mq($from: s) {
		padding-top: em(23,$base);

		svg #a {
			fill: $white;
		}

	}

}

.block--contact .h-section__number {

	@include mq($to: s) {
		top: em(-23,$base);
	}

}


/* SECTION HEADER TITLES
-------------------------------------------------------------- */
.h-section__title {
	@include typeset(18,23);
	text-transform: uppercase;
	position: relative;
	padding: em(46,18) 0 0 em(11,18);

	@include mq($to: s) {

		&:before {
			@include v-rule-before($grey-mid-light,-12px,auto,-11px,0);

			@supports (transform: translateY(0)) {
				top: -11px;
				-webkit-transform: scaleX(.5) translateY(-0.5px);
				-moz-transform: scaleX(.5) translateY(-0.5px);
				-ms-transform: scaleX(.5) translateY(-0.5px);
				-o-transform: scaleX(.5) translateY(-0.5px);
				transform: scaleX(.5) translateY(-0.5px);
			}

		}

	}

	@include mq($from: s) {
		display: block;
		position: relative;
		padding: em(11,18) 0 0;

		&:before {
			@include h-rule-before($grey-mid-light,-1px,0,auto,0);
		}

	}

}

.block--projects .h-section__title {

	@include mq($to: s) {

		&:before {			
			@supports (transform: translateY(0)) {
				bottom: -12px;
			}
		}

	}

	@include mq($from: s) {
		position: relative;
		max-width: em(253,18);

		&:before {
			border-color: $white;
		}

	}

	@include mq($from: m) {
		max-width: em(207,18);
	}

}

.section-home .block--contact .h-section__title {

	@include mq($to: s) {
		padding-top: em(57,18);

		&:before {
			top: 0;
		}

	}

}
