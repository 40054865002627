/* -------------------------------------------------------------- 

PROFILE + CONTACT

-------------------------------------------------------------- */
.block--project {

	.h-headline, p, li {
		@include typeset(18,23);
	}

	p {
		margin-bottom: em(23,$base);

		&:last-child {
			margin-bottom: 0;
		}

	}

	.detail {
		margin-bottom: em(23,$base);
	}

	.description + .description {
		margin-top: em(23,$base);

		@include mq($from: l) {
			margin-top: 0;
		}

	}

	.block--project__sub-nav {
		background: $grey-xx-light;
		position: relative;

		li {
			list-style: none;
			display: block;
			float: left;
			margin-right: .25em;

			&:after { 
				content: '/'; 
				margin-left: .25em;
			}

			&:last-child:after {
				display: none;
			}

		}

	}

}

.block--project__header {
	position: relative;

	&:after { 
		content: ''; 
		display: table; 
		clear: both;
	}

}

.block--project .h-rule {
	display: block;
	clear: both;
	position: relative;
	height: em(23,$base);
	margin-right: auto;
	margin-left: auto;
			
	&:before {
		@include h-rule-before($grey-mid-light,em(11,$base),0,0,0);
	}

	@include mq($from: s) {
		display: none;
	}

	@include mq($from: s, $to: m) {

		&.s {
			display: block;
			max-width: em(529,$base);
		}

	}

	@include mq($from: m, $to: l) {

		&.m {
			display: block;
			max-width: em(943-46,$base);
		}

		&.m-half {
			display: block;
			clear: none;
			float: left;
			width: em(437,$base);
			margin-right: 0;
			margin-left: em($l-gutter-width,$base);
		}

	}

	@include mq($from: l) {

		&.l {
			display: block;
			max-width: em(1403-46,$base);
		}

		&.l-half {
			display: block;
			clear: none;
			float: left;
			width: em(667,$base);
			margin-right: 0;
			margin-left: em($l-gutter-width,$base);
		}

	}

}

.block--project__gallery {
	background: $grey-xx-light;
	position: relative;
	// padding-bottom: em(12,$base);

	&:after { 
		content: ''; 
		display: table; 
		clear: both;
	}

	.v-rule {

		@include mq($from: m) {
			@include v-rule-before($grey-mid-light,0,auto,0,50%);
		}

		&.n2 {

			@include mq($from: m) {
				margin-left: em(230,$base);
			}

			@include mq($from: l) {
				margin-left: em(345,$base);
			}

		}

	}

	.h-rule {
		background: $grey-xx-light;
	}

	.img-wrap {
		box-shadow: 0 0 3px rgba(0,0,0,.2);
		z-index: 500;

		// -webkit-animation: lift 1s linear both;
		// -moz-animation: lift 1s linear both;
		// -o-animation: lift 1s linear both;
		// animation: lift 1s linear both;

	}

	.device {


		.img-wrap {
			background: none;
			box-shadow: none;
		}

	}

	$top-padding: em(23,$base);

	.col.top {
		padding-top: $top-padding;
	}

	@include mq($from: s, $to: m) {

		.col.s-top {
			padding-top: $top-padding;
		}

	}

	@include mq($from: m, $to: l) {

		.col.m-top {
			padding-top: $top-padding;
		}

	}

	@include mq($from: l) {

		.col.l-top {
			padding-top: $top-padding;
		}

	}

}

.block--project__contact {
	background: $grey-xx-light;
	padding-bottom: em(115,$base);

	.h-contact {
		@include typeset(18,23);
		margin-bottom: em(23,18);

		a {
			color: $white;
			text-align: center;
			text-decoration: none;
			background: $grey-x-dark;
			display: inline-block;
			position: relative;
			width: 100%;
			padding: em(23,18) em(2,18);

			@media (min-width: 321px) {
				text-align: left;
				padding: em(22,18);
			}

			@include mq($from: m) {
				width: auto;
			}

		}

	}

}


/* DIY MAGAINE
-------------------------------------------------------------- */
.diy-magazine .block--project__gallery .v-rule.n2 {

	@include mq($from: m) {
		bottom: auto;
		height: em(640,$base);
	}

	@include mq($from: l) {
		bottom: auto;
		height: em(959,$base);
	}

}


// /* ANIMATIONS
// -------------------------------------------------------------- */
// $liftStartShadow: 0 0 3px rgba(0,0,0,0);
// $liftEndShadow: 0 0 3px rgba(0,0,0,.2);

// /* WEBKIT ANIMATIONS
// -------------------------------------------------------------- */
// @-webkit-keyframes lift {
// 	0% { box-shadow: $liftStartShadow; }
// 	100% { box-shadow: $liftEndShadow; }
// }


// /* MOZ ANIMATIONS
// -------------------------------------------------------------- */
// @-moz-keyframes lift {
// 	0% { box-shadow: $liftStartShadow; }
// 	100% { box-shadow: $liftEndShadow; }
// }


// /* OPERA ANIMATIONS
// -------------------------------------------------------------- */
// @-o-keyframes lift {
// 	0% { box-shadow: $liftStartShadow; }
// 	100% { box-shadow: $liftEndShadow; }
// }


// /* ANIMATIONS
// -------------------------------------------------------------- */
// @keyframes lift {
// 	0% { box-shadow: $liftStartShadow; }
// 	100% { box-shadow: $liftEndShadow; }
// }
