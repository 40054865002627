/* -------------------------------------------------------------- 

RESET

-------------------------------------------------------------- */

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { font-size: 100%; font: inherit; border: 0; vertical-align: baseline; margin: 0; padding: 0; }
article, aside, details, figcaption, figure, footer, header[role="banner"], hgroup, menu, nav, section { display: block; }
html { overflow-y: scroll; }
a:hover, a:active { outline: 0; }
:focus { outline: 0; }
img { display: block; }
table { border-collapse: collapse; border-spacing: 0; }
caption, th, td { font-weight: 400; text-align: left; }
form { outline: 0; border: 0; }
button, input, select, textarea { font-size: 100%; border: none; vertical-align: baseline; padding: 0; margin: 0; }
button, input { line-height: normal; }
button, input[type="button"], input[type="submit"] { cursor: pointer; }
textarea { overflow: auto; vertical-align: top; }