/* -------------------------------------------------------------- 

PROJECTS

-------------------------------------------------------------- */
$mq-breakpoints: mq-add-breakpoint(laptop-breakout, 1600px);
$block-full-width-n1-m-height: 506;
$block-full-width-n1-l-height: 506;

.block--projects {
	position: relative;
	counter-reset: project;

	.project {

		@include mq($from: l) {
			margin-top: em(23,$base);
		}

	}

	img {
		margin: 0 auto;
	}

	.project__desc {
		margin: em(15,$base) 0 0;

		@include mq($from: s) {
			margin-top: em(23,$base);
		}

		p:first-child:before {
			counter-increment: project;
			content: '1.' counter(project) '. ';
		}

	}

	p, .h-headline {
		@include typeset(18,23);
		margin: 0;
	}

	.h-headline {
		text-transform: uppercase;
	}

}

.block--projects .block__full-width.n1 {
	position: relative;
	width: 100%;
	float: left;
	clear: both;

	&:after {
		content: '';
		display: table;
		clear: both;
	}

}

.block--projects .block__full-width.n1 {

	@include mq($from: l) {
		height: em($block-full-width-n1-l-height,$base);
	}

	.v-rule {
		display: none;

		@include mq($from: m, $to: l) {

			&.m {
				display: block;
				position: absolute;
				width: 1px;
				top: 0;
				bottom: 0;
				left: em(218,$base);

				&:before {
					@include v-rule-before($white,0,0,0,auto);
				}

				&.n-2 {
					left: em(678,$base);
				}

			}

		}

		@include mq($from: l) {

			&.l {
				display: block;
				position: relative;
				width: em($l-grid-width,$base);
				margin: 0 auto;

				&:before,
				&:after {
					content: '';
					display: block;
					position: absolute;

					// FIREFOX HACK
					@-moz-document url-prefix() { 
						top: 1px;
					}

				}

				&:before {
					background: $white;
					width: em(23,$base);
					height: em($block-full-width-n1-l-height,$base);
					left: 690px;
				}

				&:after {
					content: '';
					background: $grey-mid-light;
					width: 1px;
					height: em($block-full-width-n1-l-height,$base);
					position: absolute;
					left: 701px;

					// TRANSFORM FOR HALF PIXEL
					transform: scaleX(.5);
				}

			}
		
		}

	}

}

.block--projects .block__full-width .project {
	position: relative;
	float: left;
	width: 100%;
	overflow: hidden;

	@include mq($from: l) {
		width: 50%;
	}

	.project__inner {
		position: relative;

		@include mq($from: m) {
			width: em(897,$base);
			margin: 0 auto;
		}

		@include mq($from: l) {
			float: right;
			width: em(678,$base);
		}

	}

	.project__inner.left {

		@include mq($from: l) {
			float: left;
		}

	}

}

.block--projects .h-rule {
	display: block;
	clear: both;
	position: relative;
	height: em(23,$base);
	margin-right: auto;
	margin-left: auto;

	// @include mq($to: s) {
	// 	height: em(12,$base);
	// }
			
	&:before {
		// @include mq($from: s) {
			@include h-rule-before($grey-mid-light,em(11,$base),0,0,0);
		// }
	}

	&.no-grid {

		@include mq($to: xs) {
			margin-right: em(23,$base);
			margin-left: em(23,$base);
		}

		@include mq($from: xs, $to: s) {
			max-width: em(368,$base);
		}

	}

	@include mq($from: s) {
		display: none;
	}

	@include mq($from: s, $to: m) {

		&.s {
			display: block;
			max-width: em(529,$base);
		}

	}

	@include mq($from: m, $to: l) {

		&.m {
			display: block;
			max-width: em(943-46,$base);
		}

	}

	@include mq($from: l) {

		&.l {
			display: block;
			height: 0;
			max-width: em(1403-46,$base);
		}

	}

}

.block--projects .project.s-v-rule {
	position: relative;

	@include mq($from: s, $to: m) {
		
		&:before {
			@include v-rule-before($grey-mid-light,0,em(-12,$base),0,auto);
		}

	}

}

.block--projects .project.m-v-rule {
	position: relative;

	@include mq($from: m, $to: l) {
		
		&:before {
			@include v-rule-before($grey-mid-light,0,em(-12,$base),0,auto);
		}

	}

}

.block--projects .project.l-v-rule {
	position: relative;

	@include mq($from: l) {
		
		&:before {
			@include v-rule-before($grey-mid-light,0,em(-12,$base),0,auto);
		}

	}

}

/* AVAUNT
-------------------------------------------------------------- */
.block--projects .block__full-width .project.avaunt {

	@include mq($from: s) {
		color: $white;
		background: #303a42;
		position: relative;
	}

	@include mq($from: m) {
		margin-top: 0;
		margin-bottom: 0;
	}

	a:focus {
		color: #8dd7f4;
	}

	.project__inner {
		// padding-left: em(23,$base);
		// padding-right: em(23,$base);

		@include mq($from: xs) {
			// max-width: em(368,$base);
			// padding-left: 0;
			// padding-right: 0;
			margin-right: auto;
			margin-left: auto;
		}

		@include mq($from: s) {
			max-width: em(529,$base);
			height: em(414,$base);
		}

		@include mq($from: m) {
			max-width: none;
			height: em($block-full-width-n1-m-height,$base);
		}

		@include mq($from: l) {
			height: em($block-full-width-n1-l-height,$base);
		}

	}

	.project__preview {
		background: #303a42;
		position: relative;

		@include mq($from: s) {
			background: none;
			float: right;
			max-width: em(260,$base);
			padding-top: 0;
			padding-left: 0;
			margin-top: em(23,$base);
		}

		@include mq($from: m) {
			position: absolute;
			left: 50%;
			max-width: em(326,$base);
			margin-left: em(-163,$base);
		}

		@include mq($from: l) {
			right: 0;
			left: auto;
			max-width: em(326,$base);
			margin-top: em(23,$base);
			margin-right: em(123,$base);
		}

		img {
			position: relative;
			padding: em(23,$base) em(23,$base) 0;

			@include mq($from: xs) {
				max-width: em(368,$base);
				padding: em(23,$base) 0 0;
			}

			@include mq($from: s) {
				max-width: none;
				padding: 0;
			}

		}

	}

	.project__desc {
		padding: 0 em(23,$base);
		margin-right: auto;
		margin-bottom: 0;
		margin-left: auto;

		@include mq($from: xs) {
			max-width: em(368,$base);
			padding: 0;
		}

		@include mq($from: s) {
			float: left;
			position: relative;
			width: em(253,$base);
			z-index: 1000;
			padding-right: em(23,$base);
			margin-top: em(207,$base);

			&:before {
				@include h-rule-before($white,em(-12,$base),0,0,0);
			}

		}

		@include mq($from: m) {
			position: absolute;
			right: 0;
			bottom: auto;
			left: auto;
			width: em(207,$base);
			margin-top: em(184,$base);
			margin-bottom: em(23,$base);
		}

		@include mq($from: l) {
			position: relative;
			margin-top: em(414,$base);
		}

	}

}


/* A2-TYPE
-------------------------------------------------------------- */
.block--projects .block__full-width .project.a2-type {
	margin-top: 0;

	@include mq($from: s) {
		background: $grey-xx-light;
	}

	@include mq($from: l) {
		height: 100%;
	}

	.project__inner {
		// background: red;
		width: 100%;
		margin: 0 auto;

		@include mq($from: s) {
			max-width: em(529,$base);
		}

		@include mq($from: m) {
			max-width: em(897,$base);
		}

		@include mq($from: l) {
			max-width: none;
		}

		@include mq($from: laptop-breakout) {
			max-width: em(678,$base);
			padding-left: em(9,$base);
		}

	}

	.project__preview {
		background: #ccc;

		@include mq($from: s) {
			background: none;
		}

		@include mq($from: s, $to: m) {
			padding-top: em(23,$base);
		}

	}

	img {
		width: 120%;
		padding: em(23,$base);
		margin-left: -10%;

		@include mq($from: xs, $to: s) {
			position: relative;
			left: 50%;
			width: em(498,$base);
			// padding: 0;
			margin-left: em(-249,$base);
		}

		@include mq($from: s, $to: m) {
			position: absolute;
			max-width: em(399,$base); // ((115+23)*2)-23 == 253
			padding: 0;
			margin-left: em(-38,$base);
		}

		@include mq($from: m) {
			max-width: em(533,$base);
			padding: 0;
			margin-top: em(20,$base);
			margin-bottom: em(23,$base);
			// margin-left: em(-48,$base);
			margin-right: auto;
			margin-left: auto;
		}

		@include mq($from: l) {
			max-width: em(533,$base);
			margin-top: em(84,$base);
			margin-right: auto;
			margin-left: auto;
		}

		@include mq($from: laptop-breakout) {
			max-width: em(533,$base);
			float: right;
			margin-top: em(84,$base);
			margin-right: em(-48,$base);
			margin-bottom: 0;
		}

	}

	.project__desc {
		padding-right: em(23,$base);
		padding-left: em(23,$base);
		margin-right: auto;
		margin-bottom: 0;
		margin-left: auto;

		@include mq($from: xs, $to: s) {
			max-width: em(414,$base);
		}

		@include mq($from: s) {
			padding-right: 0;
			padding-left: 0;
		}

		@include mq($from: s, $to: m) {
			float: right; 
			width: em(184,$base);
			margin-top: 0;
			margin-bottom: em(184,$base);
		}

		@include mq($from: m, $to: l) {
			width: em(207,$base);
			max-width: none;
			position: absolute;
			top: 0;
			left: 0; // em(690,$base)
			margin-top: em(3,$base);
		}

		@include mq($from: l) {
			text-align: center;
			float: left;
			width: 100%;
			max-width: none;
			margin-top: em(21,$base);
		}

		@include mq($from: laptop-breakout) {
			width: em(437,$base);
			margin-left: em(233,$base);
		}

	}

}


/* DIY MAGAZINE
-------------------------------------------------------------- */
.block--projects .project.diy {

	@include mq($from: s, $to: m) {
		min-height: em(368,$base);
	}

}


/* THE SCOTT EXPEDITION
-------------------------------------------------------------- */
.block--projects .project.scott-expedition {
	position: relative;
	margin-top: 0;

	@include mq($to: s) {

		&:before {
			content: '';
			background: url('https://studiolindeman.com/img/scott-expedition/Scott_Expedition_Contour.svg') no-repeat right 0;
			display: block;
			position: absolute;
			top: 0;
			right: em(-103,$base);
			bottom: 0;
			left: em(-103,$base);
		}

	}

	@include mq($from: s) {
		background: url('https://studiolindeman.com/img/scott-expedition/Scott_Expedition_Contour.svg') no-repeat right em(-12,$base);
	}

	@include mq($from: m) {
		background-position: right 0;
		min-height: em(368,$base);
	}

	@include mq($from: l) {
		min-height: em(506,$base);
		margin-top: em(23,$base);
	}

	.project__inner {
		// background: #eee;
		position: relative;
		padding: em(23,$base) 0;

		&:after {
			content: '';
			display: table;
			clear: both;
		}

		// @include mq($from: xs) {
			
		// }

		// @include mq($from: s) {
			
		// }

		@include mq($from: m) {
			padding: em(23,$base) 0 0;
		}

		@include mq($from: l) {
			padding-top: em(69,$base);
		}

	}

	.project__preview {
		position: relative;
		float: left;
		z-index: 1000;

		@include mq($from: s) {
			width: em(324,$base);
		}

		@include mq($from: m) {
			width: em(437,$base);
		}

		@include mq($from: l) {
			width: em(437,$base); // 480
			margin-top: em(-7,$base);
			margin-left: em(115,$base);
		}

		// @include mq($from: l) {
			//
		// }

	}

	.project__desc {
		float: left;
		position: static;
		max-width: em(339,$base);
		margin-bottom: 0;

		@include mq($from: s, $to: m) {
			float: right;
			width: em(184,$base);
			margin-top: 0;
		}

		@include mq($from: m) {
			margin-top: 0;
			margin-left: em(23,$base);
		}

		@include mq($from: l) {
			// background: red;
			float: right;
			width: em(322,$base); // 207 // 322;
			// padding-right: em(115,$base);
			margin: 0;
		}

		.h-headline,
		p {
			// @include typeset(14,18);
			margin: 0;
		}

		.h-headline {
			text-transform: uppercase;
		}

		a {
			position: relative;
			z-index: 1000;
		}

	}

	.marker {

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: em(132,$base);

			@include mq($from: s) {
				background: url('https://studiolindeman.com/img/scott-expedition/Scott_Expedition_Marker-s.svg') no-repeat right top;
				right: em(-10,$base);
				width: em(303,$base);
				height: em(99,$base);
				top: em(147,$base);
			}

			@include mq($from: m) {
				background-image: url('https://studiolindeman.com/img/scott-expedition/Scott_Expedition_Marker-m.svg');
				width: em(490,$base);
				top: em(128,$base);
				right: em(-10,$base);
			}

			@include mq($from: l) {
				background-image: url('https://studiolindeman.com/img/scott-expedition/Scott_Expedition_Marker-l.svg');
				width: em(378,$base);
				top: em(168,$base);
			}

		}

	}

}


/* OAKBROOK
-------------------------------------------------------------- */
.block--projects .project.oakbrook-finance .project__preview {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.block--projects .project.oakbrook-finance .project__preview__inner {
	overflow: hidden;
	position: relative;
	padding-top: 100%;
}

.block--projects .project.oakbrook-finance .project__preview img {
	position: absolute;
	top: 0;
}

.block--projects .project.oakbrook-finance .flip-date {
	cursor: pointer;
	background: #8ad7f6;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 3000;
}

.block--projects .project.oakbrook-finance .flip-date__inner {
	position: absolute;
	top: 50%;
	left: 50%;
	width: em(176,$base);
	height: em(86,$base);
	margin: em(-36,$base) 0 0 em(-83,$base);

	@include mq($from: m) {
		width: em(210,$base);
		height: em(102,$base);
		margin: em(-50,$base) 0 0 em(-101,$base);
	}

}


/* FLIP DATE
-------------------------------------------------------------- */
.project.oakbrook-finance .project__preview .flip {
	@include typeset($base,$base);
	background: $grey-x-dark;
	border-radius: 6px;
	float: left;
	position: relative;
	width: em(36,$base);
	height: em(72,$base);
	z-index: 3000;
	margin: 0 2px 0 0;

	@include mq($from: m) {
		width: em(47,$base);
		height: em(90,$base);
	}

	&.month {
		width: em(85,$base);
		margin: 0;

		@include mq($from: m) {
			width: em(103,$base);
		}

	}

	&:before,
	&:after {
		content: '';
		speak: none;
		border-top: 2px solid $grey-x-dark;
		display: block;
		position: absolute;
		top: 50%;
		right: 0;
		left: 0;		
		z-index: 100000;
		margin-top: -1px;

		// TRANSFORM FOR HALF PIXEL
		-webkit-transform: scaleY(.25);
		-moz-transform: scaleY(.25);
		-ms-transform: scaleY(.25);
		-o-transform: scaleY(.25);
		transform: scaleY(.25);

	}

	li {
		@include typeset(72,72);
		color: $white;
		text-align: center;
		text-transform: uppercase;
		list-style: none;
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0;

		@include mq($from: m) {
			@include typeset(90,90);
		}

		.top, .bottom {
			background: $grey-x-dark;
			position: absolute;
			left: 0;
			width: 100%;
			height: 50%;
			overflow: hidden;
		}

		.top {
			border-radius: 6px 6px 0 0;
			top: 0;
			// padding-top: 0;

			-webkit-transform-origin: 50% 100%;
			-moz-transform-origin: 50% 100%;
			-o-transform-origin: 50% 100%;
			transform-origin: 50% 100%;

			// @include mq($from: m) {
			// 	padding-top: 0;
			// }

		}

		.bottom {
			// border-top: 1px solid $grey-x-dark;
			border-radius: 0 0 6px 6px;
			top: 50%;
			-webkit-transform-origin: 50% 0%;
			-moz-transform-origin: 50% 0%;
			-o-transform-origin: 50% 0%;
			transform-origin: 50% 0%;

			b {
				position: absolute;
				width: 100%;
				bottom: 0; // em(-7,81);
				left: 0;

				// @include mq($from: m) {
				// 	bottom: em(-7,90);
				// }

			}

		}
	
	}

	.active {
		opacity: 1;
	}

	li.flip-top:first-child {
		-webkit-animation: moveBack .4s linear both;
		-moz-animation: moveBack .4s linear both;
		-o-animation: moveBack .4s linear both;
		animation: moveBack .4s linear both;
	}

	li.flip-bottom:last-child {
		-webkit-animation: moveForward .4s linear both;
		-moz-animation: moveForward .4s linear both;
		-o-animation: moveForward .4s linear both;
		animation: moveForward .4s linear both;
	}

	.flip-top {

		.top {
			-webkit-animation: topTurn .4s linear both;
			-moz-animation: topTurn .4s linear both;
			-o-animation: topTurn .4s linear both;
			animation: topTurn .4s linear both;
		}

	}

	.flip-bottom {

		.bottom {
			-webkit-animation: bottomTurn .4s linear both;
			-moz-animation: bottomTurn .4s linear both;
			-o-animation: bottomTurn .4s linear both;
			animation: bottomTurn .4s linear both;
		}

	}

}


/* WEBKIT ANIMATIONS
-------------------------------------------------------------- */
@-webkit-keyframes topTurn {
	0% {
		-webkit-transform: rotateX(0deg);
	}
	50% {
		-webkit-transform: rotateX(-90deg);
	}
	100% {
		-webkit-transform: rotateX(-90deg);
	}
}

@-webkit-keyframes bottomTurn {
	0% {
		-webkit-transform: rotateX(90deg);
		z-index: 5000;
	}
	51% {
		-webkit-transform: rotateX(90deg);
	}
	99% {
		-webkit-transform: rotateX(0deg);
		z-index: 5000;
	}
	100% {
		z-index: 4000;
	}
}

@-webkit-keyframes moveForward {
	0% {
		z-index: 3000;
	}
	49% {
		z-index: 3000;
	}
	50% {
		z-index: 6000;
	}
}

@-webkit-keyframes moveBack {
	0% {
		z-index: 6000;
	}
	49% {
		z-index: 6000;
	}
	50% {
		z-index: 3000;
	}
}


/* MOZ ANIMATIONS
-------------------------------------------------------------- */
@-moz-keyframes topTurn {
	0% {
		-moz-transform: rotateX(0deg);
	}
	50% {
		-moz-transform: rotateX(-90deg);
	}
	100% {
		-moz-transform: rotateX(-90deg);
	}
}

@-moz-keyframes bottomTurn {
	0% {
		-moz-transform: rotateX(90deg);
		z-index: 5000;
	}
	51% {
		-moz-transform: rotateX(90deg);
	}
	99% {
		-moz-transform: rotateX(0deg);
		z-index: 5000;
	}
	100% {
		z-index: 4000;
	}
}

@-moz-keyframes moveForward {
	0% {
		z-index: 3000;
	}
	49% {
		z-index: 3000;
	}
	50% {
		z-index: 6000;
	}
}

@-moz-keyframes moveBack {
	0% {
		z-index: 6000;
	}
	49% {
		z-index: 6000;
	}
	50% {
		z-index: 3000;
	}
}


/* OPERA ANIMATIONS
-------------------------------------------------------------- */
@-o-keyframes topTurn {
	0% {
		-o-transform: rotateX(0deg);
	}
	50% {
		-o-transform: rotateX(-90deg);
	}
	100% {
		-o-transform: rotateX(-90deg);
	}
}

@-o-keyframes bottomTurn {
	0% {
		-o-transform: rotateX(90deg);
		z-index: 5000;
	}
	51% {
		-o-transform: rotateX(90deg);
	}
	99% {
		-o-transform: rotateX(0deg);
		z-index: 5000;
	}
	100% {
		z-index: 4000;
	}
}

@-o-keyframes moveForward {
	0% {
		z-index: 3000;
	}
	49% {
		z-index: 3000;
	}
	50% {
		z-index: 6000;
	}
}

@-o-keyframes moveBack {
	0% {
		z-index: 6000;
	}
	49% {
		z-index: 6000;
	}
	50% {
		z-index: 3000;
	}
}


/* ANIMATIONS
-------------------------------------------------------------- */
@keyframes topTurn {
	0% {
		transform: rotateX(0deg);
	}
	50% {
		transform: rotateX(-90deg);
	}
	100% {
		transform: rotateX(-90deg);
	}
}

@keyframes bottomTurn {
	0% {
		transform: rotateX(90deg);
		z-index: 5000;
	}
	51% {
		transform: rotateX(90deg);
	}
	99% {
		transform: rotateX(0deg);
		z-index: 5000;
	}
	100% {
		z-index: 4000;
	}
}

@keyframes moveForward {
	0% {
		z-index: 3000;
	}
	49% {
		z-index: 3000;
	}
	50% {
		z-index: 6000;
	}
}

@keyframes moveBack {
	0% {
		z-index: 6000;
	}
	49% {
		z-index: 6000;
	}
	50% {
		z-index: 3000;
	}
}
