/* -------------------------------------------------------------- 

MASTHEAD + FOOTER

-------------------------------------------------------------- */

/* MASTHEAD
-------------------------------------------------------------- */
.block--site-masthead {
	margin-top: 1px;

	// @include mq($to: xs) {
	// 	background: $grey-xx-light;
	// }

	.h-title {
		position: absolute;
		left: -9999px;

		// FOR TESTING SVG ALIGNMENT
		// color: red;

		// @include typeset(27,36);
		// padding: em(80,27) 0 em(23,36);

		// @include mq($from: s) {
		// 	@include typeset(36,46);
		// 	padding: em(46,36) 0 em(23,36);
		// }

	}

	.h-title-svg {
		width: 212px;
		padding: em(80,$base) 0 em(23,$base);
		margin-top: em(-6,$base);
		margin-bottom: em(-9,$base);

		@include mq($from: s) {
			width: auto;
			padding-top: em(46,$base);
			margin-top: 0;
			margin-bottom: em(-4,$base);
		}

		svg {
			max-width: 100%;
		}

		#a {
			fill: $grey-x-dark;
		}

	}

	&.sub .h-title-svg {
		padding-bottom: 0;

		@include mq($from: s) {
			padding-bottom: em(23,$base);
		}

	}

	&.contact .h-title-svg {
		
		@include mq($from: s, $to: m) {
			padding-top: em(69,$base);
		}

	}

	.h-headline {
		@include typeset(18,23);
		position: relative;
		padding-bottom: em(23,18);

		@include mq($from: s) {
			position: absolute;
			left: -9999px;
		}

	}

	.js-section-project__contact {
		@include typeset(18,23);
		position: absolute;
		top: em(23,$base);

		@include mq($from: m) {
			text-align: right;
			position: absolute;
			top: em(59,$base);
			right: em(23,$base);
		}

	}

	nav {
		position: absolute;
		top: em(23,$base);

		@include mq($to: s) {
			width: calc(100% - 46px);
		}

		@include mq($from: s) {
			text-align: right;
			top: em(59,$base);
			right: em(23,$base);
		}

	}

	ul {
		counter-reset: section;

		@include mq($to: s) {
			display: flex;
			justify-content: space-between;
		}

	}

	li {
		@include typeset(18,23);
		list-style: none;
		display: inline-block;
		margin-right: .25em;
	}

	li:last-child {
		float: right;
		margin-right: 0;

		&:after {
			content: '';
		}

	}

}

.block--site-masthead a {
	text-decoration: none;
}

.block--site-masthead .block__grid {
	overflow: hidden;
	position: relative;

	&:after {
		@include h-rule-before($grey-mid-light,auto,em(23,$base),em(11,$base),em(23,$base));
		// @include mq($from: s) {
		// 	bottom: em(12,$base);
		// }
	}

	&:after {

		@include mq($from: s) {
			right: em($s-gutter-width,$base);
			left: em($s-gutter-width,$base);
		}

		@include mq($from: m) {
			right: em($m-gutter-width,$base);
			left: em($m-gutter-width,$base);
		}

		@include mq($from: l) {
			right: em($l-gutter-width,$base);
			left: em($l-gutter-width,$base);
		}

	}

}


/* FOOTER
-------------------------------------------------------------- */
.block--site-footer {
	color: $grey;
	background: $grey-xx-dark;
	padding-top: em(24,$base);
	padding-bottom: em(46,$base);

	p {
		@include typeset(18,23);
	}

	.back-to-top {
		margin-top: em(23,18);
		
		@include mq($from: s) {
			margin-top: 0;
		}
		
		@include mq($from: l) {
			text-align: right;
		}

	}

	small {
		@include mq($from: m) {
			display: block;
		}
	}

}

.block--site-footer a {
	&:hover, &:active, &:focus {
		color: $white;
	}
}