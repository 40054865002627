/* -------------------------------------------------------------- 

PROFILE + CONTACT

-------------------------------------------------------------- */
body .block--profile .block__grid,
body .block--contact .block__grid {
	position: relative;
}

.block--profile .v-rule,
.block--contact .v-rule {

	@include mq($from: s) {
		display: none;
		position: absolute;
		top: 0;
		bottom: em(23,$base);
	}

	@include mq($from: s) {

		&.n-1 {
			display: block;
			left: em(150,$base);
		}

	}

	@include mq($from: m) {

		&.n-1 {
			left: em(241,$base);
		}

	}

	@include mq($from: l) {

		&.n-2 {
			display: block;
			left: em(701,$base);
		}

		&.n-3 {
			display: block;
			left: em(1046,$base);
		}

	}

	&:before {
		@include mq($from: s) {
			@include v-rule-before($grey-mid-light,0,auto,0,auto);
		}
	}

}

.block--contact .v-rule {

	@include mq($from: s) {
		bottom: 0;
	}

}


/* PROFILE
-------------------------------------------------------------- */
.block--profile {
	position: relative;

	@include mq($from: l) {
		margin-top: em(23,$base);
	}

	.h-headline, p {
		@include typeset(18,23);
		margin: 0 0 em(23,18);
	}

}

body .block--profile .block__grid {
	padding-bottom: em(46,$base);

	@include mq($from: s) {
		padding-top: em(23,$base);
		// padding-bottom: em(46,$base);
	}

	&:before {
		@include h-rule-before($grey-mid-light,auto,em(23,$base),em(12,$base),em(23,$base));
		
		@include mq($from: s) {
			right: em($s-gutter-width,$base);
			left: em($s-gutter-width,$base);
		}
		
		@include mq($from: m) {
			right: em($m-gutter-width,$base);
			left: em($m-gutter-width,$base);
		}
		
		@include mq($from: l) {
			right: em($l-gutter-width,$base);
			left: em($l-gutter-width,$base);
		}

	}

}

// .block--profile__studio {

// 	@include mq($from: m) {
// 		position: relative;
// 		padding-bottom: em(23,$base);

// 		&:before {
// 			@include h-rule-before($grey-x-dark,auto,0,em(23,$base),0);
// 		}

// 	}

// }

.block--profile__studio {

	@include mq($from: m, $to: l) {
		position: relative;
		padding-bottom: em(12,$base);

		&:before {
			@include h-rule-before($grey-x-dark,auto,0,em(12,$base),0);
		}

	}

}
	
.block--profile__director {
	position: relative;

	@include mq($to: m) {
		padding-top: em(23,18);

		&:before {
			@include h-rule-before($grey-x-dark,em(11,$base),0,auto,0);
		}

	}

	// @include mq($from: m, $to: l) {
	// 	padding-top: em(11,$base);
	// }

	p, .h-name, .h-role, .h-contact {
		@include typeset(18,23);
		margin: 0;

	}

	&.james {

		@include mq($from: m) {
			max-width: em(320,18); // col width: 322
		}
		
	}

	&.ileana {

		@include mq($to: m) {
			margin-top: em(23,18);
		}

		@include mq($from: m, $to: l) {

			&:before {
				@include v-rule-before($grey-x-dark,0,auto,em(-23,$base),em(-12,$base));
			}

		}
		
	}

	.h-name {

		@include mq($from: s) {
			display: inline-block;
		}

	}

	.h-role {

		@include mq($from: s) {
			display: inline-block;
			margin-left: 0; // .25em;

			&:before {
				content: '–';
				speak: none;
				margin: 0 .25em;
			}

		}

	}

	.h-contact {
		// color: $grey;
		margin-bottom: em(23,18);
	}

	p {
		margin-bottom: em(23,18);

		&:last-child {
			margin-bottom: 0;
		}

	}

}


/* CONTACT
-------------------------------------------------------------- */
.block--contact {
	background: $grey-xx-light;
	position: relative;
	overflow: hidden;

	.h-headline, p {
		@include typeset(18,23);
		margin: 0 0 em(23,18);
	}

	.h-headline, p {
		@include typeset(18,23);
	}

	.h-headline {
		margin: 0 0 em(23,18);

		@include mq($from: s, $to: m) {
			margin-bottom: 0;
		}

	}

	.h-contact {
		@include typeset(18,23);
		margin: em(23,18) 0;

		a {
			text-align: center;
			text-decoration: none;
			display: inline-block;
			position: relative;
			width: 100%;
			background: $white;
			padding: em(23,18) em(2,18);

			@media (min-width: 321px) {
				text-align: left;
				padding: em(22,18);
			}

		}

	}

	.location p {
		margin: 0;
	}

}

.csstransforms .block--contact .h-contact a:after {
	content: '';
	speak: none;
	display: block;
	border: 1px solid $grey-mid-light;
	position: absolute;
	width: 200%;
	height: 200%;
	top: 0;
	left: 0;

	// TRANSFORM FOR HALF PIXEL
	-webkit-transform: scale(.5);
	-moz-transform: scale(.5);
	-ms-transform: scale(.5);
	-o-transform: scale(.5);
	transform: scale(.5);

	-webkit-transform-origin: 0% 0%;
	-moz-transform-origin: 0% 0%;
	-ms-transform-origin: 0% 0%;
	-o-transform-origin: 0% 0%;
	transform-origin: 0% 0%;

	// FIREFOX HACK
	@media (-moz-windows-theme) {
		display: none;
	}

}

body .block--contact .block__grid {
	padding-bottom: em(115,$base);

	@include mq($from: s) {
		padding-top: em(23,$base);
	}

}