/* -------------------------------------------------------------- 

GRIDS

-------------------------------------------------------------- */

/* Default grid for small screens and up
-------------------------------------------------------------- */
$s-gutter-width: 18; // gutter width in pixels

.block { display:block; position: relative; }
.block__grid { display:block; width: 100%; max-width: em(414,$base); padding: 0 em(23,$base); margin:0 auto; }

.block:after, .block__grid:after { content: ''; display: table; clear: both; }

.col { float: left; width: 100%; }

// h1, h2, h3, h4, h5, h6, p, ul {

// 	// Pad text not columns
// 	@include mq($to: xs) {
// 		@include rem(padding-right, 23);
// 		@include rem(padding-left, 23);
// 	}

// }


/* ‘XS’ for extra small screens
-------------------------------------------------------------- */
// @include mq($from: xs) {

// 	.block__grid { 
// 		padding: 0 em(23,$base);
// 	}

// }


/* Grid ‘S’ for small screens, 4 column grid
Variables and functions are set as columns widths are equal

((115+23)*4)+23
-------------------------------------------------------------- */
$s-grid-width: 575; // block__grid width minus padding in pixels
$s-1-width: 115; // single column width in pixels
$s-gutter-width: 23; // gutter width in pixels

@function s-col-width($n) {
	$target: ($n * $s-1-width) + (($n - 1) * $s-gutter-width);
	@return ($target/$s-grid-width) * 100+0%;
}

@function s-push($n) {
	$target: ($n * $s-1-width) + ($n * $s-gutter-width) + $s-gutter-width;
	@return ($target/$s-grid-width) * 100+0%;
}

@include mq($from: s, $to: m) {

	.block__grid { max-width: em(575,$base); padding: 0; }

	.s-break { display: block; clear: both; }
	.s-clear { clear: both; }
	.s-clear-r { clear: right; }
	.s-clear-l { clear: left; }
	.s-right { float: right; margin-right: per($s-gutter-width,$s-grid-width); margin-left: 0; }

	.col { padding: 0; margin-left: per($s-gutter-width,$s-grid-width); }
	.col-s { float: left; width: 100%; padding: 0; margin-left: per($s-gutter-width,$s-grid-width); }

	.s-1 { width: s-col-width(1); }
	.s-2 { width: s-col-width(2); }
	.s-3 { width: s-col-width(3); }
	.s-4 { width: s-col-width(4); }

}


/* Grid ‘M’ for medium screens, 8 column grid
Variables and functions are set as columns widths are equal

((92+23)*8)+23

-------------------------------------------------------------- */
$m-grid-width: 943; // block__grid width minus padding in pixels
$m-1-width: 92; // single column width in pixels
$m-gutter-width: 23; // gutter width in pixels

@function m-col-width($n) {
	$target: ($n * $m-1-width) + (($n - 1) * $m-gutter-width);
	@return ($target/$m-grid-width) * 100+0%;
}

@function m-push($n) {
	$target: ($n * $m-1-width) + ($n * $m-gutter-width) + $m-gutter-width;
	@return ($target/$m-grid-width) * 100+0%;
}

@include mq($from: m, $to: l) {

	.block__grid { max-width: em(943,$base); padding: 0; }

	.m-break { display: block; clear: both; }
	.m-clear { clear: both; }
	.m-clear-r { clear: right; }
	.m-clear-l { clear: left; }
	.m-right { float: right; }

	.col { padding: 0; margin-left: per($m-gutter-width,$m-grid-width); }
	.col-m { float: left; width: 100%; padding: 0; margin-left: per($m-gutter-width,$m-grid-width); }
	.col.m-right, .col-m.m-right { float: right; clear: right; margin-left: 0; margin-right: per($m-gutter-width,$m-grid-width); }

	.m-1 { width: m-col-width(1); }
	.m-2 { width: m-col-width(2); }
	.m-3 { width: m-col-width(3); }
	.m-4 { width: m-col-width(4); }
	.m-5 { width: m-col-width(5); }
	.m-6 { width: m-col-width(6); }
	.m-7 { width: m-col-width(7); }
	.m-8 { width: m-col-width(8); }

	.m-push-1 { margin-left: m-push(1); }
	.m-push-2 { margin-left: m-push(2); }
	.m-push-3 { margin-left: m-push(3); }

	.m-right.m-push-1 { margin-left: 0; margin-right: m-push(1); }
	.m-right.m-push-2 { margin-left: 0; margin-right: m-push(2); }
	.m-right.m-push-3 { margin-left: 0; margin-right: m-push(3); }

}


/* Grid ‘L’ for large screens, 12 column grid
Variables and functions are set as columns widths are equal

((92+23)*12)+23

-------------------------------------------------------------- */
$l-grid-width: 1403; // block__grid width minus padding in pixels
$l-1-width: 92; // single column width in pixels
$l-gutter-width: 23; // gutter width in pixels

@function l-col-width($n) {
	$target: ($n * $l-1-width) + (($n - 1) * $l-gutter-width);
	@return ($target/$l-grid-width) * 100+0%;
}

@function l-push($n) {
	$target: ($n * $l-1-width) + ($n * $l-gutter-width) + $l-gutter-width;
	@return ($target/$l-grid-width) * 100+0%;
}

@include mq($from: l) {

	.block__grid { max-width: em(1403,$base); padding: 0; }

	.l-break { display: block; clear: both; }
	.l-clear { clear: both; }
	.l-clear-r { clear: right; }
	.l-clear-l { clear: left; }
	.l-right { float: right; }
	
	.col { padding: 0; margin-left: per($l-gutter-width,$l-grid-width); }
	.col-l { float: left; width: 100%; padding: 0; margin-left: per($l-gutter-width,$l-grid-width); }
	.col.l-right, .col-l.l-right { float: right; clear: right; margin-left: 0; margin-right: per($l-gutter-width,$l-grid-width); }

	.l-1 { width: l-col-width(1); }
	.l-2 { width: l-col-width(2); }
	.l-3 { width: l-col-width(3); }
	.l-4 { width: l-col-width(4); }
	.l-5 { width: l-col-width(5); }
	.l-6 { width: l-col-width(6); }
	.l-7 { width: l-col-width(7); }
	.l-8 { width: l-col-width(8); }
	.l-9 { width: l-col-width(9); }
	.l-10 { width: l-col-width(10); }
	.l-11 { width: l-col-width(11); }
	.l-12 { width: l-col-width(12); }

	.l-push-1 { margin-left: l-push(1); }
	.l-push-2 { margin-left: l-push(2); }
	.l-push-3 { margin-left: l-push(3); }
	.l-push-4 { margin-left: l-push(4); }

	.l-right.l-push-1 { margin-left: 0; margin-right: l-push(1); }
	.l-right.l-push-2 { margin-left: 0; margin-right: l-push(2); }

}

