/* -------------------------------------------------------------- 

Media queries

Based on https://github.com/guardian/sass-mq

-------------------------------------------------------------- */

// Set breakpoints in pixels
$mq-breakpoints: (
	xs: 415px,
	s: 575px,
	m: 961px,
	l: 1421px
) !default;

@function mq-get-breakpoint-width($name) {
	@if(map-has-key($mq-breakpoints, $name)) {
		@return map-get($mq-breakpoints, $name);
	} @else {
		@return 'Breakpoint #{$name} does not exist';
	}
}

@mixin mq($from: false, $to: false, $and: false) {

	// Initialize variables
	$min-width: 0;
	$max-width: 0;
	$mediaQuery: '';

	// From: this breakpoint (inclusive)
	@if $from {
		@if type-of($from) == number {
			$min-width: $from;
		} @else {
			$min-width: mq-get-breakpoint-width($from);
		}
	}

	// To: that breakpoint (exclusive)
	@if $to {
		@if type-of($to) == number {
			$max-width: $to;
		} @else {
			$max-width: mq-get-breakpoint-width($to) - 1px;
		}
	}

	// Output media queries
	@if $min-width != 0 { $mediaQuery: '#{$mediaQuery} and (min-width: #{$min-width})'; }
	@if $max-width != 0 { $mediaQuery: '#{$mediaQuery} and (max-width: #{$max-width})'; }
	@if $and            { $mediaQuery: '#{$mediaQuery} and #{$and}'; }

	$mediaQuery: unquote('#{$mediaQuery}');

	@media all #{$mediaQuery} {
		@content;
	}

}

// Add a breakpoint
// Usage: $mq-breakpoints: mq-add-breakpoint(tvscreen, 1920px);
@function mq-add-breakpoint($name, $breakpoint) {
	$new-breakpoint: (#{$name}: $breakpoint);
	@return map-merge($mq-breakpoints, $new-breakpoint);
}


// Show the active breakpoint in the top right corner of the viewport
$mq-show-breakpoints: () !default; // $mq-show-breakpoints: (m,l) !default;
@if (length($mq-show-breakpoints) > 0) {
	body:before {
		font-size: 12px;
		line-height: 18px;
		color: #aaa;
		text-align: center;
		text-transform: uppercase;
		padding: 3px 6px;
		position: absolute;
		right: 0;
		top: 0;
		width: 120px;
		z-index: 10000;

		// Loop through the breakpoints that should be shown
		@each $show-breakpoint in $mq-show-breakpoints {
			$width: mq-get-breakpoint-width($show-breakpoint);
			@include mq($show-breakpoint) {
				content: "#{$show-breakpoint} ≥ #{$width}";
			}
		}
	
	}
}

