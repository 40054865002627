@charset "UTF-8";
/* -------------------------------------------------------------- 

STYLES COMBINED

-------------------------------------------------------------- */
/* -------------------------------------------------------------- 

RESET

-------------------------------------------------------------- */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  border: 0;
  vertical-align: baseline;
  margin: 0;
  padding: 0; }

article, aside, details, figcaption, figure, footer, header[role="banner"], hgroup, menu, nav, section {
  display: block; }

html {
  overflow-y: scroll; }

a:hover, a:active {
  outline: 0; }

:focus {
  outline: 0; }

img {
  display: block; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th, td {
  font-weight: 400;
  text-align: left; }

form {
  outline: 0;
  border: 0; }

button, input, select, textarea {
  font-size: 100%;
  border: none;
  vertical-align: baseline;
  padding: 0;
  margin: 0; }

button, input {
  line-height: normal; }

button, input[type="button"], input[type="submit"] {
  cursor: pointer; }

textarea {
  overflow: auto;
  vertical-align: top; }

/* -------------------------------------------------------------- 

SCSS EXTENSIONS

1. BASE FONT SIZE
2. RULES
3. UNIT CONVERTERS

--------------------------------------------------------------

1. BASE FONT SIZE
This is the pixel value but do not include ‘px’
-------------------------------------------------------------- */
/* -------------------------------------------------------------- 

2. RULES
-------------------------------------------------------------- */
/* -------------------------------------------------------------- 

3. UNIT CONVERTERS

Guide to units: 
rem = font-sizes, use typeset() to set font-size and line-height
% = for responsive elements, use per() to calculate
px = media queries and border widths
em = for almost everything else; non-responsive widths, 
max-widths, padding, margins, use em() to calculate

-------------------------------------------------------------- */
/* PERCENTAGES
Example use: per(500, 1000)
would output: 50%
-------------------------------------------------------------- */
/* FONT SIZE AND LINE HEIGHT
Example use (if $base is set to 18): @include typeset(18,24);
would output: font-size: 18px; font-size: 1rem; line-height: 1.33333;
-------------------------------------------------------------- */
/* EMS
Example use: em(12, 24)
would output: 0.5em
-------------------------------------------------------------- */
/* LINE-HEIGHT - for when typeset is not appropriate
Example use: lh(24, 12)
would output: 2
-------------------------------------------------------------- */
/* REMS - for where you might want to use rem as a unit for 
something other than font-size
Example use (if $base is set to 18): @include rem(margin,18 0);
would output: margin: 18px 0; margin: 1rem 0;
-------------------------------------------------------------- */
/* -------------------------------------------------------------- 

Media queries

Based on https://github.com/guardian/sass-mq

-------------------------------------------------------------- */
/* -------------------------------------------------------------- 

COLOURS

-------------------------------------------------------------- */
/* -------------------------------------------------------------- 

GRIDS

-------------------------------------------------------------- */
/* Default grid for small screens and up
-------------------------------------------------------------- */
.block {
  display: block;
  position: relative; }

.block__grid {
  display: block;
  width: 100%;
  max-width: 23em;
  padding: 0 1.27778em;
  margin: 0 auto; }

.block:after, .block__grid:after {
  content: '';
  display: table;
  clear: both; }

.col {
  float: left;
  width: 100%; }

/* ‘XS’ for extra small screens
-------------------------------------------------------------- */
/* Grid ‘S’ for small screens, 4 column grid
Variables and functions are set as columns widths are equal

((115+23)*4)+23
-------------------------------------------------------------- */
@media all and (min-width: 575px) and (max-width: 960px) {
  .block__grid {
    max-width: 31.94444em;
    padding: 0; }
  .s-break {
    display: block;
    clear: both; }
  .s-clear {
    clear: both; }
  .s-clear-r {
    clear: right; }
  .s-clear-l {
    clear: left; }
  .s-right {
    float: right;
    margin-right: 4%;
    margin-left: 0; }
  .col {
    padding: 0;
    margin-left: 4%; }
  .col-s {
    float: left;
    width: 100%;
    padding: 0;
    margin-left: 4%; }
  .s-1 {
    width: 20%; }
  .s-2 {
    width: 44%; }
  .s-3 {
    width: 68%; }
  .s-4 {
    width: 92%; } }

/* Grid ‘M’ for medium screens, 8 column grid
Variables and functions are set as columns widths are equal

((92+23)*8)+23

-------------------------------------------------------------- */
@media all and (min-width: 961px) and (max-width: 1420px) {
  .block__grid {
    max-width: 52.38889em;
    padding: 0; }
  .m-break {
    display: block;
    clear: both; }
  .m-clear {
    clear: both; }
  .m-clear-r {
    clear: right; }
  .m-clear-l {
    clear: left; }
  .m-right {
    float: right; }
  .col {
    padding: 0;
    margin-left: 2.43902%; }
  .col-m {
    float: left;
    width: 100%;
    padding: 0;
    margin-left: 2.43902%; }
  .col.m-right, .col-m.m-right {
    float: right;
    clear: right;
    margin-left: 0;
    margin-right: 2.43902%; }
  .m-1 {
    width: 9.7561%; }
  .m-2 {
    width: 21.95122%; }
  .m-3 {
    width: 34.14634%; }
  .m-4 {
    width: 46.34146%; }
  .m-5 {
    width: 58.53659%; }
  .m-6 {
    width: 70.73171%; }
  .m-7 {
    width: 82.92683%; }
  .m-8 {
    width: 95.12195%; }
  .m-push-1 {
    margin-left: 14.63415%; }
  .m-push-2 {
    margin-left: 26.82927%; }
  .m-push-3 {
    margin-left: 39.02439%; }
  .m-right.m-push-1 {
    margin-left: 0;
    margin-right: 14.63415%; }
  .m-right.m-push-2 {
    margin-left: 0;
    margin-right: 26.82927%; }
  .m-right.m-push-3 {
    margin-left: 0;
    margin-right: 39.02439%; } }

/* Grid ‘L’ for large screens, 12 column grid
Variables and functions are set as columns widths are equal

((92+23)*12)+23

-------------------------------------------------------------- */
@media all and (min-width: 1421px) {
  .block__grid {
    max-width: 77.94444em;
    padding: 0; }
  .l-break {
    display: block;
    clear: both; }
  .l-clear {
    clear: both; }
  .l-clear-r {
    clear: right; }
  .l-clear-l {
    clear: left; }
  .l-right {
    float: right; }
  .col {
    padding: 0;
    margin-left: 1.63934%; }
  .col-l {
    float: left;
    width: 100%;
    padding: 0;
    margin-left: 1.63934%; }
  .col.l-right, .col-l.l-right {
    float: right;
    clear: right;
    margin-left: 0;
    margin-right: 1.63934%; }
  .l-1 {
    width: 6.55738%; }
  .l-2 {
    width: 14.7541%; }
  .l-3 {
    width: 22.95082%; }
  .l-4 {
    width: 31.14754%; }
  .l-5 {
    width: 39.34426%; }
  .l-6 {
    width: 47.54098%; }
  .l-7 {
    width: 55.7377%; }
  .l-8 {
    width: 63.93443%; }
  .l-9 {
    width: 72.13115%; }
  .l-10 {
    width: 80.32787%; }
  .l-11 {
    width: 88.52459%; }
  .l-12 {
    width: 96.72131%; }
  .l-push-1 {
    margin-left: 9.83607%; }
  .l-push-2 {
    margin-left: 18.03279%; }
  .l-push-3 {
    margin-left: 26.22951%; }
  .l-push-4 {
    margin-left: 34.42623%; }
  .l-right.l-push-1 {
    margin-left: 0;
    margin-right: 9.83607%; }
  .l-right.l-push-2 {
    margin-left: 0;
    margin-right: 18.03279%; } }

/* -------------------------------------------------------------- 

BASE STYLES

-------------------------------------------------------------- */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html, body {
  font-size: 18px;
  line-height: 1;
  color: #222;
  background: #fff;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html {
  height: 100%; }

body {
  min-height: 100%;
  -webkit-backface-visibility: hidden; }

html.loaded, .loaded body {
  color: #777;
  background: #111; }

body.show-grid .page:before {
  content: '';
  background: url("/ui/img/grid-l.png") repeat-y 0 0;
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1403px;
  height: 1000%;
  opacity: .15;
  z-index: 1000; }
  @media all and (min-width: 415px) {
    body.show-grid .page:before {
      left: 50%;
      margin-left: -701.5px; } }

.page {
  color: #222;
  background: #fff;
  width: 100%;
  overflow: hidden; }

/* WORD BREAKS AND HYPHENATION + PADDING
-------------------------------------------------------------- */
h1, h2, h3, h4, h5, h6, p, li, dd, cite, blockquote, a, div {
  word-wrap: break-word; }

/* NO LINE BREAKS
-------------------------------------------------------------- */
.anobr {
  white-space: nowrap; }

@media all and (min-width: 415px) and (max-width: 574px) {
  .nobr-xs {
    white-space: nowrap; } }

@media all and (min-width: 961px) and (max-width: 1420px) {
  .nobr-m {
    white-space: nowrap; } }

@media all and (min-width: 961px) {
  .nobr {
    white-space: nowrap; } }

/* Links
-------------------------------------------------------------- */
a {
  color: inherit;
  text-decoration: underline; }
  a:focus {
    color: #1779c7; }

/* Images
-------------------------------------------------------------- */
img {
  width: 100%;
  height: auto; }

.img-wrap {
  background: #fff;
  position: relative;
  overflow: hidden;
  margin: 0 auto; }
  .img-wrap img {
    transition: all 700ms;
    position: absolute;
    top: 0;
    left: 0; }

.js img.unveil {
  opacity: 0; }
  .js img.unveil.lazyloaded {
    opacity: 1; }

/* -------------------------------------------------------------- 

FONT-FACE

-------------------------------------------------------------- */
@font-face {
  font-family: 'Sanomat Sans Bold Web';
  src: url("https://studiolindeman.com/ui/fonts/SanomatSans-Bold-Web.woff2") format("woff2"), url("https://studiolindeman.com/ui/fonts/SanomatSans-Bold-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'Sanomat Sans Text Regular Web';
  src: url("/ui/fonts/SanomatSansText-Regular-Web.woff2") format("woff2"), url("/ui/fonts/SanomatSansText-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap; }

@font-face {
  font-family: 'Action Cond Medium Grade 2 Web';
  src: url("/ui/fonts/ActionCondensedMedium-Grade2-Web.woff2") format("woff2"), url("/ui/fonts/ActionCondensedMedium-Grade2-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap; }

/* -------------------------------------------------------------- 

FONTS

For all font-families and weights

-------------------------------------------------------------- */
body {
  -webkit-font-smoothing: antialiased;
  -moz-font-feature-settings: 'liga' 1;
  -moz-font-feature-settings: 'liga=1';
  -ms-font-feature-settings: 'liga' 1;
  -o-font-feature-settings: 'liga' 1;
  -webkit-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga' 1; }

body,
input,
textarea {
  font-family: sans-serif;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

.sanomatSansTextRegularWeb body,
.sanomatSansTextRegularWeb input,
.sanomatSansTextRegularWeb textarea {
  font-family: 'Sanomat Sans Text Regular Web', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

.h-section:before {
  font-family: 'Sanomat Sans Bold Web', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal; }

.sanomatSansTextRegularWeb .oakbrook-finance .flip-date {
  font-family: 'Action Cond Medium Grade 2 Web', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

/* -------------------------------------------------------------- 

FORMS

-------------------------------------------------------------- */
form {
  outline: 0;
  border: 0; }

form ol {
  list-style: none; }

input[type='text'],
input[type='submit'],
input[type='email'],
textarea {
  font-size: 18px;
  font-size: 1rem;
  line-height: 1.66667;
  color: palette(grey, dark);
  background: #fff;
  border: 1px solid palette(grey, x-light);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  width: 100%; }
  input[type='text']:hover, input[type='text']:active, input[type='text']:focus,
  input[type='submit']:hover,
  input[type='submit']:active,
  input[type='submit']:focus,
  input[type='email']:hover,
  input[type='email']:active,
  input[type='email']:focus,
  textarea:hover,
  textarea:active,
  textarea:focus {
    border-color: palette(grey); }

input[type='text'],
input[type='submit'],
input[type='email'] {
  padding: 0.55556em 0.83333em;
  -webkit-appearance: none; }

input[type='checkbox'] {
  -webkit-appearance: checkbox; }

textarea {
  padding: 0.33333em 0.5em;
  -webkit-appearance: none; }

label {
  font-size: 18px;
  font-size: 1rem;
  line-height: 1.66667;
  display: block;
  margin: 0 0 0.33333em; }

input[type='submit'].submit {
  width: auto;
  -webkit-appearance: none; }
  input[type='submit'].submit:hover, input[type='submit'].submit:active, input[type='submit'].submit:focus {
    cursor: pointer; }

/* MAILING LIST
-------------------------------------------------------------- */
.mailing-list .mailing-list__inputs {
  overflow: hidden; }
  .mailing-list .mailing-list__inputs:hover input {
    border-color: palette(grey); }

.mailing-list .mailing-list__email {
  width: 9.16667em;
  height: 2.88889em; }
  @media all and (min-width: 961px) {
    .mailing-list .mailing-list__email {
      width: 14.66667em; } }

.mailing-list .mailing-list__submit {
  border-left: none;
  height: 2.88889em; }
  .mailing-list .mailing-list__submit:hover, .mailing-list .mailing-list__submit:active, .mailing-list .mailing-list__submit:focus {
    border-color: palette(grey, x-light); }

.mailing-list .btn-submit
.mailing-list__email:focus + .mailing-list__submit {
  border-color: palette(grey); }

/* -------------------------------------------------------------- 

MASTHEAD + FOOTER

-------------------------------------------------------------- */
/* MASTHEAD
-------------------------------------------------------------- */
.block--site-masthead {
  margin-top: 1px; }
  .block--site-masthead .h-title {
    position: absolute;
    left: -9999px; }
  .block--site-masthead .h-title-svg {
    width: 212px;
    padding: 4.44444em 0 1.27778em;
    margin-top: -0.33333em;
    margin-bottom: -0.5em; }
    @media all and (min-width: 575px) {
      .block--site-masthead .h-title-svg {
        width: auto;
        padding-top: 2.55556em;
        margin-top: 0;
        margin-bottom: -0.22222em; } }
    .block--site-masthead .h-title-svg svg {
      max-width: 100%; }
    .block--site-masthead .h-title-svg #a {
      fill: #222; }
  .block--site-masthead.sub .h-title-svg {
    padding-bottom: 0; }
    @media all and (min-width: 575px) {
      .block--site-masthead.sub .h-title-svg {
        padding-bottom: 1.27778em; } }
  @media all and (min-width: 575px) and (max-width: 960px) {
    .block--site-masthead.contact .h-title-svg {
      padding-top: 3.83333em; } }
  .block--site-masthead .h-headline {
    font-size: 18px;
    font-size: 1rem;
    line-height: 1.27778;
    position: relative;
    padding-bottom: 1.27778em; }
    @media all and (min-width: 575px) {
      .block--site-masthead .h-headline {
        position: absolute;
        left: -9999px; } }
  .block--site-masthead .js-section-project__contact {
    font-size: 18px;
    font-size: 1rem;
    line-height: 1.27778;
    position: absolute;
    top: 1.27778em; }
    @media all and (min-width: 961px) {
      .block--site-masthead .js-section-project__contact {
        text-align: right;
        position: absolute;
        top: 3.27778em;
        right: 1.27778em; } }
  .block--site-masthead nav {
    position: absolute;
    top: 1.27778em; }
    @media all and (max-width: 574px) {
      .block--site-masthead nav {
        width: calc(100% - 46px); } }
    @media all and (min-width: 575px) {
      .block--site-masthead nav {
        text-align: right;
        top: 3.27778em;
        right: 1.27778em; } }
  .block--site-masthead ul {
    counter-reset: section; }
    @media all and (max-width: 574px) {
      .block--site-masthead ul {
        display: flex;
        justify-content: space-between; } }
  .block--site-masthead li {
    font-size: 18px;
    font-size: 1rem;
    line-height: 1.27778;
    list-style: none;
    display: inline-block;
    margin-right: .25em; }
  .block--site-masthead li:last-child {
    float: right;
    margin-right: 0; }
    .block--site-masthead li:last-child:after {
      content: ''; }

.block--site-masthead a {
  text-decoration: none; }

.block--site-masthead .block__grid {
  overflow: hidden;
  position: relative; }
  .block--site-masthead .block__grid:after {
    content: '';
    speak: none;
    display: block;
    border-top: 1px solid #222;
    position: absolute;
    height: 0;
    top: auto;
    right: 1.27778em;
    bottom: 0.61111em;
    left: 1.27778em;
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    -o-transform: scaleY(0.5);
    transform: scaleY(0.5); }
  @media all and (min-width: 575px) {
    .block--site-masthead .block__grid:after {
      right: 1.27778em;
      left: 1.27778em; } }
  @media all and (min-width: 961px) {
    .block--site-masthead .block__grid:after {
      right: 1.27778em;
      left: 1.27778em; } }
  @media all and (min-width: 1421px) {
    .block--site-masthead .block__grid:after {
      right: 1.27778em;
      left: 1.27778em; } }

/* FOOTER
-------------------------------------------------------------- */
.block--site-footer {
  color: #777;
  background: #111;
  padding-top: 1.33333em;
  padding-bottom: 2.55556em; }
  .block--site-footer p {
    font-size: 18px;
    font-size: 1rem;
    line-height: 1.27778; }
  .block--site-footer .back-to-top {
    margin-top: 1.27778em; }
    @media all and (min-width: 575px) {
      .block--site-footer .back-to-top {
        margin-top: 0; } }
    @media all and (min-width: 1421px) {
      .block--site-footer .back-to-top {
        text-align: right; } }
  @media all and (min-width: 961px) {
    .block--site-footer small {
      display: block; } }

.block--site-footer a:hover, .block--site-footer a:active, .block--site-footer a:focus {
  color: #fff; }

/* -------------------------------------------------------------- 

MODULES

-------------------------------------------------------------- */
/* SECTION HEADERS
-------------------------------------------------------------- */
.block--projects .h-section-wrap {
  padding-right: 1.27778em;
  padding-left: 1.27778em; }
  @media all and (min-width: 575px) {
    .block--projects .h-section-wrap {
      background: none;
      max-width: none; } }

.h-section {
  position: relative;
  padding-bottom: 1.27778em;
  padding-left: 3.83333em; }
  @media all and (min-width: 575px) {
    .h-section {
      padding-left: 0;
      margin-bottom: 0; } }
  .h-section:after {
    content: '';
    speak: none;
    display: block;
    border-top: 1px solid #222;
    position: absolute;
    height: 0;
    top: auto;
    right: 0;
    bottom: 0.66667em;
    left: 0;
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    -o-transform: scaleY(0.5);
    transform: scaleY(0.5); }
    @media all and (min-width: 575px) {
      .h-section:after {
        display: none; } }

.block--projects .h-section {
  margin-right: auto;
  margin-bottom: -0.66667em;
  margin-left: auto; }
  @media all and (min-width: 415px) {
    .block--projects .h-section {
      max-width: 20.44444em; } }
  @media all and (min-width: 575px) {
    .block--projects .h-section {
      color: #fff;
      width: 29.38889em;
      height: 12.77778em;
      max-width: none;
      z-index: 100;
      padding-left: 0;
      margin-bottom: -12.38889em; } }
  @media all and (min-width: 961px) {
    .block--projects .h-section {
      width: 49.83333em;
      height: 14.05556em;
      margin-bottom: -14.27778em; } }
  @media all and (min-width: 1421px) {
    .block--projects .h-section {
      width: 75.38889em; } }
  .block--projects .h-section:after {
    display: none; }

/* SECTION HEADER NUMBERS
-------------------------------------------------------------- */
.h-section__number {
  position: absolute;
  top: -1.83333em;
  left: 0;
  width: 3.44444em; }
  .h-section__number svg {
    max-width: 100%; }
    .h-section__number svg #a {
      fill: #222; }
  @media all and (min-width: 575px) {
    .h-section__number {
      position: static;
      width: 6.11111em;
      margin: -0.44444em 0 -0.16667em; } }
  @media all and (min-width: 961px) {
    .h-section__number {
      width: 10.38889em;
      margin: 0.16667em 0 0.5em; } }

@media all and (min-width: 575px) {
  .block--projects .h-section__number {
    padding-top: 1.27778em; }
    .block--projects .h-section__number svg #a {
      fill: #fff; } }

@media all and (max-width: 574px) {
  .block--contact .h-section__number {
    top: -1.27778em; } }

/* SECTION HEADER TITLES
-------------------------------------------------------------- */
.h-section__title {
  font-size: 18px;
  font-size: 1rem;
  line-height: 1.27778;
  text-transform: uppercase;
  position: relative;
  padding: 2.55556em 0 0 0.61111em; }
  @media all and (max-width: 574px) {
    .h-section__title:before {
      content: '';
      speak: none;
      display: block;
      border-left: 1px solid #222;
      position: absolute;
      width: 0;
      top: -12px;
      right: auto;
      bottom: -11px;
      left: 0;
      -webkit-transform: scaleX(0.5);
      -moz-transform: scaleX(0.5);
      -ms-transform: scaleX(0.5);
      -o-transform: scaleX(0.5);
      transform: scaleX(0.5); }
      @supports (transform: translateY(0)) {
        .h-section__title:before {
          top: -11px;
          -webkit-transform: scaleX(0.5) translateY(-0.5px);
          -moz-transform: scaleX(0.5) translateY(-0.5px);
          -ms-transform: scaleX(0.5) translateY(-0.5px);
          -o-transform: scaleX(0.5) translateY(-0.5px);
          transform: scaleX(0.5) translateY(-0.5px); } } }
  @media all and (min-width: 575px) {
    .h-section__title {
      display: block;
      position: relative;
      padding: 0.61111em 0 0; }
      .h-section__title:before {
        content: '';
        speak: none;
        display: block;
        border-top: 1px solid #222;
        position: absolute;
        height: 0;
        top: -1px;
        right: 0;
        bottom: auto;
        left: 0;
        -webkit-transform: scaleY(0.5);
        -moz-transform: scaleY(0.5);
        -ms-transform: scaleY(0.5);
        -o-transform: scaleY(0.5);
        transform: scaleY(0.5); } }

@media all and (max-width: 574px) {
  @supports (transform: translateY(0)) {
    .block--projects .h-section__title:before {
      bottom: -12px; } } }

@media all and (min-width: 575px) {
  .block--projects .h-section__title {
    position: relative;
    max-width: 14.05556em; }
    .block--projects .h-section__title:before {
      border-color: #fff; } }

@media all and (min-width: 961px) {
  .block--projects .h-section__title {
    max-width: 11.5em; } }

@media all and (max-width: 574px) {
  .section-home .block--contact .h-section__title {
    padding-top: 3.16667em; }
    .section-home .block--contact .h-section__title:before {
      top: 0; } }

/* -------------------------------------------------------------- 

PROJECTS

-------------------------------------------------------------- */
.block--projects {
  position: relative;
  counter-reset: project; }
  @media all and (min-width: 1421px) {
    .block--projects .project {
      margin-top: 1.27778em; } }
  .block--projects img {
    margin: 0 auto; }
  .block--projects .project__desc {
    margin: 0.83333em 0 0; }
    @media all and (min-width: 575px) {
      .block--projects .project__desc {
        margin-top: 1.27778em; } }
    .block--projects .project__desc p:first-child:before {
      counter-increment: project;
      content: "1." counter(project) ". "; }
  .block--projects p, .block--projects .h-headline {
    font-size: 18px;
    font-size: 1rem;
    line-height: 1.27778;
    margin: 0; }
  .block--projects .h-headline {
    text-transform: uppercase; }

.block--projects .block__full-width.n1 {
  position: relative;
  width: 100%;
  float: left;
  clear: both; }
  .block--projects .block__full-width.n1:after {
    content: '';
    display: table;
    clear: both; }

@media all and (min-width: 1421px) {
  .block--projects .block__full-width.n1 {
    height: 28.11111em; } }

.block--projects .block__full-width.n1 .v-rule {
  display: none; }
  @media all and (min-width: 961px) and (max-width: 1420px) {
    .block--projects .block__full-width.n1 .v-rule.m {
      display: block;
      position: absolute;
      width: 1px;
      top: 0;
      bottom: 0;
      left: 12.11111em; }
      .block--projects .block__full-width.n1 .v-rule.m:before {
        content: '';
        speak: none;
        display: block;
        border-left: 1px solid #fff;
        position: absolute;
        width: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: auto;
        -webkit-transform: scaleX(0.5);
        -moz-transform: scaleX(0.5);
        -ms-transform: scaleX(0.5);
        -o-transform: scaleX(0.5);
        transform: scaleX(0.5); }
      .block--projects .block__full-width.n1 .v-rule.m.n-2 {
        left: 37.66667em; } }
  @media all and (min-width: 1421px) {
    .block--projects .block__full-width.n1 .v-rule.l {
      display: block;
      position: relative;
      width: 77.94444em;
      margin: 0 auto; }
      .block--projects .block__full-width.n1 .v-rule.l:before, .block--projects .block__full-width.n1 .v-rule.l:after {
        content: '';
        display: block;
        position: absolute; }
    @-moz-document url-prefix() {
      .block--projects .block__full-width.n1 .v-rule.l:before, .block--projects .block__full-width.n1 .v-rule.l:after {
        top: 1px; } }
      .block--projects .block__full-width.n1 .v-rule.l:before {
        background: #fff;
        width: 1.27778em;
        height: 28.11111em;
        left: 690px; }
      .block--projects .block__full-width.n1 .v-rule.l:after {
        content: '';
        background: #222;
        width: 1px;
        height: 28.11111em;
        position: absolute;
        left: 701px;
        transform: scaleX(0.5); } }

.block--projects .block__full-width .project {
  position: relative;
  float: left;
  width: 100%;
  overflow: hidden; }
  @media all and (min-width: 1421px) {
    .block--projects .block__full-width .project {
      width: 50%; } }
  .block--projects .block__full-width .project .project__inner {
    position: relative; }
    @media all and (min-width: 961px) {
      .block--projects .block__full-width .project .project__inner {
        width: 49.83333em;
        margin: 0 auto; } }
    @media all and (min-width: 1421px) {
      .block--projects .block__full-width .project .project__inner {
        float: right;
        width: 37.66667em; } }
  @media all and (min-width: 1421px) {
    .block--projects .block__full-width .project .project__inner.left {
      float: left; } }

.block--projects .h-rule {
  display: block;
  clear: both;
  position: relative;
  height: 1.27778em;
  margin-right: auto;
  margin-left: auto; }
  .block--projects .h-rule:before {
    content: '';
    speak: none;
    display: block;
    border-top: 1px solid #222;
    position: absolute;
    height: 0;
    top: 0.61111em;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    -o-transform: scaleY(0.5);
    transform: scaleY(0.5); }
  @media all and (max-width: 414px) {
    .block--projects .h-rule.no-grid {
      margin-right: 1.27778em;
      margin-left: 1.27778em; } }
  @media all and (min-width: 415px) and (max-width: 574px) {
    .block--projects .h-rule.no-grid {
      max-width: 20.44444em; } }
  @media all and (min-width: 575px) {
    .block--projects .h-rule {
      display: none; } }
  @media all and (min-width: 575px) and (max-width: 960px) {
    .block--projects .h-rule.s {
      display: block;
      max-width: 29.38889em; } }
  @media all and (min-width: 961px) and (max-width: 1420px) {
    .block--projects .h-rule.m {
      display: block;
      max-width: 49.83333em; } }
  @media all and (min-width: 1421px) {
    .block--projects .h-rule.l {
      display: block;
      height: 0;
      max-width: 75.38889em; } }

.block--projects .project.s-v-rule {
  position: relative; }
  @media all and (min-width: 575px) and (max-width: 960px) {
    .block--projects .project.s-v-rule:before {
      content: '';
      speak: none;
      display: block;
      border-left: 1px solid #222;
      position: absolute;
      width: 0;
      top: 0;
      right: -0.66667em;
      bottom: 0;
      left: auto;
      -webkit-transform: scaleX(0.5);
      -moz-transform: scaleX(0.5);
      -ms-transform: scaleX(0.5);
      -o-transform: scaleX(0.5);
      transform: scaleX(0.5); } }

.block--projects .project.m-v-rule {
  position: relative; }
  @media all and (min-width: 961px) and (max-width: 1420px) {
    .block--projects .project.m-v-rule:before {
      content: '';
      speak: none;
      display: block;
      border-left: 1px solid #222;
      position: absolute;
      width: 0;
      top: 0;
      right: -0.66667em;
      bottom: 0;
      left: auto;
      -webkit-transform: scaleX(0.5);
      -moz-transform: scaleX(0.5);
      -ms-transform: scaleX(0.5);
      -o-transform: scaleX(0.5);
      transform: scaleX(0.5); } }

.block--projects .project.l-v-rule {
  position: relative; }
  @media all and (min-width: 1421px) {
    .block--projects .project.l-v-rule:before {
      content: '';
      speak: none;
      display: block;
      border-left: 1px solid #222;
      position: absolute;
      width: 0;
      top: 0;
      right: -0.66667em;
      bottom: 0;
      left: auto;
      -webkit-transform: scaleX(0.5);
      -moz-transform: scaleX(0.5);
      -ms-transform: scaleX(0.5);
      -o-transform: scaleX(0.5);
      transform: scaleX(0.5); } }

/* AVAUNT
-------------------------------------------------------------- */
@media all and (min-width: 575px) {
  .block--projects .block__full-width .project.avaunt {
    color: #fff;
    background: #303a42;
    position: relative; } }

@media all and (min-width: 961px) {
  .block--projects .block__full-width .project.avaunt {
    margin-top: 0;
    margin-bottom: 0; } }

.block--projects .block__full-width .project.avaunt a:focus {
  color: #8dd7f4; }

@media all and (min-width: 415px) {
  .block--projects .block__full-width .project.avaunt .project__inner {
    margin-right: auto;
    margin-left: auto; } }

@media all and (min-width: 575px) {
  .block--projects .block__full-width .project.avaunt .project__inner {
    max-width: 29.38889em;
    height: 23em; } }

@media all and (min-width: 961px) {
  .block--projects .block__full-width .project.avaunt .project__inner {
    max-width: none;
    height: 28.11111em; } }

@media all and (min-width: 1421px) {
  .block--projects .block__full-width .project.avaunt .project__inner {
    height: 28.11111em; } }

.block--projects .block__full-width .project.avaunt .project__preview {
  background: #303a42;
  position: relative; }
  @media all and (min-width: 575px) {
    .block--projects .block__full-width .project.avaunt .project__preview {
      background: none;
      float: right;
      max-width: 14.44444em;
      padding-top: 0;
      padding-left: 0;
      margin-top: 1.27778em; } }
  @media all and (min-width: 961px) {
    .block--projects .block__full-width .project.avaunt .project__preview {
      position: absolute;
      left: 50%;
      max-width: 18.11111em;
      margin-left: -9.05556em; } }
  @media all and (min-width: 1421px) {
    .block--projects .block__full-width .project.avaunt .project__preview {
      right: 0;
      left: auto;
      max-width: 18.11111em;
      margin-top: 1.27778em;
      margin-right: 6.83333em; } }
  .block--projects .block__full-width .project.avaunt .project__preview img {
    position: relative;
    padding: 1.27778em 1.27778em 0; }
    @media all and (min-width: 415px) {
      .block--projects .block__full-width .project.avaunt .project__preview img {
        max-width: 20.44444em;
        padding: 1.27778em 0 0; } }
    @media all and (min-width: 575px) {
      .block--projects .block__full-width .project.avaunt .project__preview img {
        max-width: none;
        padding: 0; } }

.block--projects .block__full-width .project.avaunt .project__desc {
  padding: 0 1.27778em;
  margin-right: auto;
  margin-bottom: 0;
  margin-left: auto; }
  @media all and (min-width: 415px) {
    .block--projects .block__full-width .project.avaunt .project__desc {
      max-width: 20.44444em;
      padding: 0; } }
  @media all and (min-width: 575px) {
    .block--projects .block__full-width .project.avaunt .project__desc {
      float: left;
      position: relative;
      width: 14.05556em;
      z-index: 1000;
      padding-right: 1.27778em;
      margin-top: 11.5em; }
      .block--projects .block__full-width .project.avaunt .project__desc:before {
        content: '';
        speak: none;
        display: block;
        border-top: 1px solid #fff;
        position: absolute;
        height: 0;
        top: -0.66667em;
        right: 0;
        bottom: 0;
        left: 0;
        -webkit-transform: scaleY(0.5);
        -moz-transform: scaleY(0.5);
        -ms-transform: scaleY(0.5);
        -o-transform: scaleY(0.5);
        transform: scaleY(0.5); } }
  @media all and (min-width: 961px) {
    .block--projects .block__full-width .project.avaunt .project__desc {
      position: absolute;
      right: 0;
      bottom: auto;
      left: auto;
      width: 11.5em;
      margin-top: 10.22222em;
      margin-bottom: 1.27778em; } }
  @media all and (min-width: 1421px) {
    .block--projects .block__full-width .project.avaunt .project__desc {
      position: relative;
      margin-top: 23em; } }

/* A2-TYPE
-------------------------------------------------------------- */
.block--projects .block__full-width .project.a2-type {
  margin-top: 0; }
  @media all and (min-width: 575px) {
    .block--projects .block__full-width .project.a2-type {
      background: #e4e4e4; } }
  @media all and (min-width: 1421px) {
    .block--projects .block__full-width .project.a2-type {
      height: 100%; } }
  .block--projects .block__full-width .project.a2-type .project__inner {
    width: 100%;
    margin: 0 auto; }
    @media all and (min-width: 575px) {
      .block--projects .block__full-width .project.a2-type .project__inner {
        max-width: 29.38889em; } }
    @media all and (min-width: 961px) {
      .block--projects .block__full-width .project.a2-type .project__inner {
        max-width: 49.83333em; } }
    @media all and (min-width: 1421px) {
      .block--projects .block__full-width .project.a2-type .project__inner {
        max-width: none; } }
    @media all and (min-width: 1600px) {
      .block--projects .block__full-width .project.a2-type .project__inner {
        max-width: 37.66667em;
        padding-left: 0.5em; } }
  .block--projects .block__full-width .project.a2-type .project__preview {
    background: #ccc; }
    @media all and (min-width: 575px) {
      .block--projects .block__full-width .project.a2-type .project__preview {
        background: none; } }
    @media all and (min-width: 575px) and (max-width: 960px) {
      .block--projects .block__full-width .project.a2-type .project__preview {
        padding-top: 1.27778em; } }
  .block--projects .block__full-width .project.a2-type img {
    width: 120%;
    padding: 1.27778em;
    margin-left: -10%; }
    @media all and (min-width: 415px) and (max-width: 574px) {
      .block--projects .block__full-width .project.a2-type img {
        position: relative;
        left: 50%;
        width: 27.66667em;
        margin-left: -13.83333em; } }
    @media all and (min-width: 575px) and (max-width: 960px) {
      .block--projects .block__full-width .project.a2-type img {
        position: absolute;
        max-width: 22.16667em;
        padding: 0;
        margin-left: -2.11111em; } }
    @media all and (min-width: 961px) {
      .block--projects .block__full-width .project.a2-type img {
        max-width: 29.61111em;
        padding: 0;
        margin-top: 1.11111em;
        margin-bottom: 1.27778em;
        margin-right: auto;
        margin-left: auto; } }
    @media all and (min-width: 1421px) {
      .block--projects .block__full-width .project.a2-type img {
        max-width: 29.61111em;
        margin-top: 4.66667em;
        margin-right: auto;
        margin-left: auto; } }
    @media all and (min-width: 1600px) {
      .block--projects .block__full-width .project.a2-type img {
        max-width: 29.61111em;
        float: right;
        margin-top: 4.66667em;
        margin-right: -2.66667em;
        margin-bottom: 0; } }
  .block--projects .block__full-width .project.a2-type .project__desc {
    padding-right: 1.27778em;
    padding-left: 1.27778em;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto; }
    @media all and (min-width: 415px) and (max-width: 574px) {
      .block--projects .block__full-width .project.a2-type .project__desc {
        max-width: 23em; } }
    @media all and (min-width: 575px) {
      .block--projects .block__full-width .project.a2-type .project__desc {
        padding-right: 0;
        padding-left: 0; } }
    @media all and (min-width: 575px) and (max-width: 960px) {
      .block--projects .block__full-width .project.a2-type .project__desc {
        float: right;
        width: 10.22222em;
        margin-top: 0;
        margin-bottom: 10.22222em; } }
    @media all and (min-width: 961px) and (max-width: 1420px) {
      .block--projects .block__full-width .project.a2-type .project__desc {
        width: 11.5em;
        max-width: none;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 0.16667em; } }
    @media all and (min-width: 1421px) {
      .block--projects .block__full-width .project.a2-type .project__desc {
        text-align: center;
        float: left;
        width: 100%;
        max-width: none;
        margin-top: 1.16667em; } }
    @media all and (min-width: 1600px) {
      .block--projects .block__full-width .project.a2-type .project__desc {
        width: 24.27778em;
        margin-left: 12.94444em; } }

/* DIY MAGAZINE
-------------------------------------------------------------- */
@media all and (min-width: 575px) and (max-width: 960px) {
  .block--projects .project.diy {
    min-height: 20.44444em; } }

/* THE SCOTT EXPEDITION
-------------------------------------------------------------- */
.block--projects .project.scott-expedition {
  position: relative;
  margin-top: 0; }
  @media all and (max-width: 574px) {
    .block--projects .project.scott-expedition:before {
      content: '';
      background: url("https://studiolindeman.com/img/scott-expedition/Scott_Expedition_Contour.svg") no-repeat right 0;
      display: block;
      position: absolute;
      top: 0;
      right: -5.72222em;
      bottom: 0;
      left: -5.72222em; } }
  @media all and (min-width: 575px) {
    .block--projects .project.scott-expedition {
      background: url("https://studiolindeman.com/img/scott-expedition/Scott_Expedition_Contour.svg") no-repeat right -0.66667em; } }
  @media all and (min-width: 961px) {
    .block--projects .project.scott-expedition {
      background-position: right 0;
      min-height: 20.44444em; } }
  @media all and (min-width: 1421px) {
    .block--projects .project.scott-expedition {
      min-height: 28.11111em;
      margin-top: 1.27778em; } }
  .block--projects .project.scott-expedition .project__inner {
    position: relative;
    padding: 1.27778em 0; }
    .block--projects .project.scott-expedition .project__inner:after {
      content: '';
      display: table;
      clear: both; }
    @media all and (min-width: 961px) {
      .block--projects .project.scott-expedition .project__inner {
        padding: 1.27778em 0 0; } }
    @media all and (min-width: 1421px) {
      .block--projects .project.scott-expedition .project__inner {
        padding-top: 3.83333em; } }
  .block--projects .project.scott-expedition .project__preview {
    position: relative;
    float: left;
    z-index: 1000; }
    @media all and (min-width: 575px) {
      .block--projects .project.scott-expedition .project__preview {
        width: 18em; } }
    @media all and (min-width: 961px) {
      .block--projects .project.scott-expedition .project__preview {
        width: 24.27778em; } }
    @media all and (min-width: 1421px) {
      .block--projects .project.scott-expedition .project__preview {
        width: 24.27778em;
        margin-top: -0.38889em;
        margin-left: 6.38889em; } }
  .block--projects .project.scott-expedition .project__desc {
    float: left;
    position: static;
    max-width: 18.83333em;
    margin-bottom: 0; }
    @media all and (min-width: 575px) and (max-width: 960px) {
      .block--projects .project.scott-expedition .project__desc {
        float: right;
        width: 10.22222em;
        margin-top: 0; } }
    @media all and (min-width: 961px) {
      .block--projects .project.scott-expedition .project__desc {
        margin-top: 0;
        margin-left: 1.27778em; } }
    @media all and (min-width: 1421px) {
      .block--projects .project.scott-expedition .project__desc {
        float: right;
        width: 17.88889em;
        margin: 0; } }
    .block--projects .project.scott-expedition .project__desc .h-headline,
    .block--projects .project.scott-expedition .project__desc p {
      margin: 0; }
    .block--projects .project.scott-expedition .project__desc .h-headline {
      text-transform: uppercase; }
    .block--projects .project.scott-expedition .project__desc a {
      position: relative;
      z-index: 1000; }
  .block--projects .project.scott-expedition .marker:before {
    content: '';
    display: block;
    position: absolute;
    top: 7.33333em; }
    @media all and (min-width: 575px) {
      .block--projects .project.scott-expedition .marker:before {
        background: url("https://studiolindeman.com/img/scott-expedition/Scott_Expedition_Marker-s.svg") no-repeat right top;
        right: -0.55556em;
        width: 16.83333em;
        height: 5.5em;
        top: 8.16667em; } }
    @media all and (min-width: 961px) {
      .block--projects .project.scott-expedition .marker:before {
        background-image: url("https://studiolindeman.com/img/scott-expedition/Scott_Expedition_Marker-m.svg");
        width: 27.22222em;
        top: 7.11111em;
        right: -0.55556em; } }
    @media all and (min-width: 1421px) {
      .block--projects .project.scott-expedition .marker:before {
        background-image: url("https://studiolindeman.com/img/scott-expedition/Scott_Expedition_Marker-l.svg");
        width: 21em;
        top: 9.33333em; } }

/* OAKBROOK
-------------------------------------------------------------- */
.block--projects .project.oakbrook-finance .project__preview {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.block--projects .project.oakbrook-finance .project__preview__inner {
  overflow: hidden;
  position: relative;
  padding-top: 100%; }

.block--projects .project.oakbrook-finance .project__preview img {
  position: absolute;
  top: 0; }

.block--projects .project.oakbrook-finance .flip-date {
  cursor: pointer;
  background: #8ad7f6;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3000; }

.block--projects .project.oakbrook-finance .flip-date__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 9.77778em;
  height: 4.77778em;
  margin: -2em 0 0 -4.61111em; }
  @media all and (min-width: 961px) {
    .block--projects .project.oakbrook-finance .flip-date__inner {
      width: 11.66667em;
      height: 5.66667em;
      margin: -2.77778em 0 0 -5.61111em; } }

/* FLIP DATE
-------------------------------------------------------------- */
.project.oakbrook-finance .project__preview .flip {
  font-size: 18px;
  font-size: 1rem;
  line-height: 1;
  background: #222;
  border-radius: 6px;
  float: left;
  position: relative;
  width: 2em;
  height: 4em;
  z-index: 3000;
  margin: 0 2px 0 0; }
  @media all and (min-width: 961px) {
    .project.oakbrook-finance .project__preview .flip {
      width: 2.61111em;
      height: 5em; } }
  .project.oakbrook-finance .project__preview .flip.month {
    width: 4.72222em;
    margin: 0; }
    @media all and (min-width: 961px) {
      .project.oakbrook-finance .project__preview .flip.month {
        width: 5.72222em; } }
  .project.oakbrook-finance .project__preview .flip:before, .project.oakbrook-finance .project__preview .flip:after {
    content: '';
    speak: none;
    border-top: 2px solid #222;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    z-index: 100000;
    margin-top: -1px;
    -webkit-transform: scaleY(0.25);
    -moz-transform: scaleY(0.25);
    -ms-transform: scaleY(0.25);
    -o-transform: scaleY(0.25);
    transform: scaleY(0.25); }
  .project.oakbrook-finance .project__preview .flip li {
    font-size: 72px;
    font-size: 4rem;
    line-height: 1;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    list-style: none;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0; }
    @media all and (min-width: 961px) {
      .project.oakbrook-finance .project__preview .flip li {
        font-size: 90px;
        font-size: 5rem;
        line-height: 1; } }
    .project.oakbrook-finance .project__preview .flip li .top, .project.oakbrook-finance .project__preview .flip li .bottom {
      background: #222;
      position: absolute;
      left: 0;
      width: 100%;
      height: 50%;
      overflow: hidden; }
    .project.oakbrook-finance .project__preview .flip li .top {
      border-radius: 6px 6px 0 0;
      top: 0;
      -webkit-transform-origin: 50% 100%;
      -moz-transform-origin: 50% 100%;
      -o-transform-origin: 50% 100%;
      transform-origin: 50% 100%; }
    .project.oakbrook-finance .project__preview .flip li .bottom {
      border-radius: 0 0 6px 6px;
      top: 50%;
      -webkit-transform-origin: 50% 0%;
      -moz-transform-origin: 50% 0%;
      -o-transform-origin: 50% 0%;
      transform-origin: 50% 0%; }
      .project.oakbrook-finance .project__preview .flip li .bottom b {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0; }
  .project.oakbrook-finance .project__preview .flip .active {
    opacity: 1; }
  .project.oakbrook-finance .project__preview .flip li.flip-top:first-child {
    -webkit-animation: moveBack .4s linear both;
    -moz-animation: moveBack .4s linear both;
    -o-animation: moveBack .4s linear both;
    animation: moveBack .4s linear both; }
  .project.oakbrook-finance .project__preview .flip li.flip-bottom:last-child {
    -webkit-animation: moveForward .4s linear both;
    -moz-animation: moveForward .4s linear both;
    -o-animation: moveForward .4s linear both;
    animation: moveForward .4s linear both; }
  .project.oakbrook-finance .project__preview .flip .flip-top .top {
    -webkit-animation: topTurn .4s linear both;
    -moz-animation: topTurn .4s linear both;
    -o-animation: topTurn .4s linear both;
    animation: topTurn .4s linear both; }
  .project.oakbrook-finance .project__preview .flip .flip-bottom .bottom {
    -webkit-animation: bottomTurn .4s linear both;
    -moz-animation: bottomTurn .4s linear both;
    -o-animation: bottomTurn .4s linear both;
    animation: bottomTurn .4s linear both; }

/* WEBKIT ANIMATIONS
-------------------------------------------------------------- */
@-webkit-keyframes topTurn {
  0% {
    -webkit-transform: rotateX(0deg); }
  50% {
    -webkit-transform: rotateX(-90deg); }
  100% {
    -webkit-transform: rotateX(-90deg); } }

@-webkit-keyframes bottomTurn {
  0% {
    -webkit-transform: rotateX(90deg);
    z-index: 5000; }
  51% {
    -webkit-transform: rotateX(90deg); }
  99% {
    -webkit-transform: rotateX(0deg);
    z-index: 5000; }
  100% {
    z-index: 4000; } }

@-webkit-keyframes moveForward {
  0% {
    z-index: 3000; }
  49% {
    z-index: 3000; }
  50% {
    z-index: 6000; } }

@-webkit-keyframes moveBack {
  0% {
    z-index: 6000; }
  49% {
    z-index: 6000; }
  50% {
    z-index: 3000; } }

/* MOZ ANIMATIONS
-------------------------------------------------------------- */
@-moz-keyframes topTurn {
  0% {
    -moz-transform: rotateX(0deg); }
  50% {
    -moz-transform: rotateX(-90deg); }
  100% {
    -moz-transform: rotateX(-90deg); } }

@-moz-keyframes bottomTurn {
  0% {
    -moz-transform: rotateX(90deg);
    z-index: 5000; }
  51% {
    -moz-transform: rotateX(90deg); }
  99% {
    -moz-transform: rotateX(0deg);
    z-index: 5000; }
  100% {
    z-index: 4000; } }

@-moz-keyframes moveForward {
  0% {
    z-index: 3000; }
  49% {
    z-index: 3000; }
  50% {
    z-index: 6000; } }

@-moz-keyframes moveBack {
  0% {
    z-index: 6000; }
  49% {
    z-index: 6000; }
  50% {
    z-index: 3000; } }

/* OPERA ANIMATIONS
-------------------------------------------------------------- */
@-o-keyframes topTurn {
  0% {
    -o-transform: rotateX(0deg); }
  50% {
    -o-transform: rotateX(-90deg); }
  100% {
    -o-transform: rotateX(-90deg); } }

@-o-keyframes bottomTurn {
  0% {
    -o-transform: rotateX(90deg);
    z-index: 5000; }
  51% {
    -o-transform: rotateX(90deg); }
  99% {
    -o-transform: rotateX(0deg);
    z-index: 5000; }
  100% {
    z-index: 4000; } }

@-o-keyframes moveForward {
  0% {
    z-index: 3000; }
  49% {
    z-index: 3000; }
  50% {
    z-index: 6000; } }

@-o-keyframes moveBack {
  0% {
    z-index: 6000; }
  49% {
    z-index: 6000; }
  50% {
    z-index: 3000; } }

/* ANIMATIONS
-------------------------------------------------------------- */
@keyframes topTurn {
  0% {
    transform: rotateX(0deg); }
  50% {
    transform: rotateX(-90deg); }
  100% {
    transform: rotateX(-90deg); } }

@keyframes bottomTurn {
  0% {
    transform: rotateX(90deg);
    z-index: 5000; }
  51% {
    transform: rotateX(90deg); }
  99% {
    transform: rotateX(0deg);
    z-index: 5000; }
  100% {
    z-index: 4000; } }

@keyframes moveForward {
  0% {
    z-index: 3000; }
  49% {
    z-index: 3000; }
  50% {
    z-index: 6000; } }

@keyframes moveBack {
  0% {
    z-index: 6000; }
  49% {
    z-index: 6000; }
  50% {
    z-index: 3000; } }

/* -------------------------------------------------------------- 

PROFILE + CONTACT

-------------------------------------------------------------- */
body .block--profile .block__grid,
body .block--contact .block__grid {
  position: relative; }

@media all and (min-width: 575px) {
  .block--profile .v-rule,
  .block--contact .v-rule {
    display: none;
    position: absolute;
    top: 0;
    bottom: 1.27778em; } }

@media all and (min-width: 575px) {
  .block--profile .v-rule.n-1,
  .block--contact .v-rule.n-1 {
    display: block;
    left: 8.33333em; } }

@media all and (min-width: 961px) {
  .block--profile .v-rule.n-1,
  .block--contact .v-rule.n-1 {
    left: 13.38889em; } }

@media all and (min-width: 1421px) {
  .block--profile .v-rule.n-2,
  .block--contact .v-rule.n-2 {
    display: block;
    left: 38.94444em; }
  .block--profile .v-rule.n-3,
  .block--contact .v-rule.n-3 {
    display: block;
    left: 58.11111em; } }

@media all and (min-width: 575px) {
  .block--profile .v-rule:before,
  .block--contact .v-rule:before {
    content: '';
    speak: none;
    display: block;
    border-left: 1px solid #222;
    position: absolute;
    width: 0;
    top: 0;
    right: auto;
    bottom: 0;
    left: auto;
    -webkit-transform: scaleX(0.5);
    -moz-transform: scaleX(0.5);
    -ms-transform: scaleX(0.5);
    -o-transform: scaleX(0.5);
    transform: scaleX(0.5); } }

@media all and (min-width: 575px) {
  .block--contact .v-rule {
    bottom: 0; } }

/* PROFILE
-------------------------------------------------------------- */
.block--profile {
  position: relative; }
  @media all and (min-width: 1421px) {
    .block--profile {
      margin-top: 1.27778em; } }
  .block--profile .h-headline, .block--profile p {
    font-size: 18px;
    font-size: 1rem;
    line-height: 1.27778;
    margin: 0 0 1.27778em; }

body .block--profile .block__grid {
  padding-bottom: 2.55556em; }
  @media all and (min-width: 575px) {
    body .block--profile .block__grid {
      padding-top: 1.27778em; } }
  body .block--profile .block__grid:before {
    content: '';
    speak: none;
    display: block;
    border-top: 1px solid #222;
    position: absolute;
    height: 0;
    top: auto;
    right: 1.27778em;
    bottom: 0.66667em;
    left: 1.27778em;
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    -o-transform: scaleY(0.5);
    transform: scaleY(0.5); }
    @media all and (min-width: 575px) {
      body .block--profile .block__grid:before {
        right: 1.27778em;
        left: 1.27778em; } }
    @media all and (min-width: 961px) {
      body .block--profile .block__grid:before {
        right: 1.27778em;
        left: 1.27778em; } }
    @media all and (min-width: 1421px) {
      body .block--profile .block__grid:before {
        right: 1.27778em;
        left: 1.27778em; } }

@media all and (min-width: 961px) and (max-width: 1420px) {
  .block--profile__studio {
    position: relative;
    padding-bottom: 0.66667em; }
    .block--profile__studio:before {
      content: '';
      speak: none;
      display: block;
      border-top: 1px solid #222;
      position: absolute;
      height: 0;
      top: auto;
      right: 0;
      bottom: 0.66667em;
      left: 0;
      -webkit-transform: scaleY(0.5);
      -moz-transform: scaleY(0.5);
      -ms-transform: scaleY(0.5);
      -o-transform: scaleY(0.5);
      transform: scaleY(0.5); } }

.block--profile__director {
  position: relative; }
  @media all and (max-width: 960px) {
    .block--profile__director {
      padding-top: 1.27778em; }
      .block--profile__director:before {
        content: '';
        speak: none;
        display: block;
        border-top: 1px solid #222;
        position: absolute;
        height: 0;
        top: 0.61111em;
        right: 0;
        bottom: auto;
        left: 0;
        -webkit-transform: scaleY(0.5);
        -moz-transform: scaleY(0.5);
        -ms-transform: scaleY(0.5);
        -o-transform: scaleY(0.5);
        transform: scaleY(0.5); } }
  .block--profile__director p, .block--profile__director .h-name, .block--profile__director .h-role, .block--profile__director .h-contact {
    font-size: 18px;
    font-size: 1rem;
    line-height: 1.27778;
    margin: 0; }
  @media all and (min-width: 961px) {
    .block--profile__director.james {
      max-width: 17.77778em; } }
  @media all and (max-width: 960px) {
    .block--profile__director.ileana {
      margin-top: 1.27778em; } }
  @media all and (min-width: 961px) and (max-width: 1420px) {
    .block--profile__director.ileana:before {
      content: '';
      speak: none;
      display: block;
      border-left: 1px solid #222;
      position: absolute;
      width: 0;
      top: 0;
      right: auto;
      bottom: -1.27778em;
      left: -0.66667em;
      -webkit-transform: scaleX(0.5);
      -moz-transform: scaleX(0.5);
      -ms-transform: scaleX(0.5);
      -o-transform: scaleX(0.5);
      transform: scaleX(0.5); } }
  @media all and (min-width: 575px) {
    .block--profile__director .h-name {
      display: inline-block; } }
  @media all and (min-width: 575px) {
    .block--profile__director .h-role {
      display: inline-block;
      margin-left: 0; }
      .block--profile__director .h-role:before {
        content: '–';
        speak: none;
        margin: 0 .25em; } }
  .block--profile__director .h-contact {
    margin-bottom: 1.27778em; }
  .block--profile__director p {
    margin-bottom: 1.27778em; }
    .block--profile__director p:last-child {
      margin-bottom: 0; }

/* CONTACT
-------------------------------------------------------------- */
.block--contact {
  background: #e4e4e4;
  position: relative;
  overflow: hidden; }
  .block--contact .h-headline, .block--contact p {
    font-size: 18px;
    font-size: 1rem;
    line-height: 1.27778;
    margin: 0 0 1.27778em; }
  .block--contact .h-headline, .block--contact p {
    font-size: 18px;
    font-size: 1rem;
    line-height: 1.27778; }
  .block--contact .h-headline {
    margin: 0 0 1.27778em; }
    @media all and (min-width: 575px) and (max-width: 960px) {
      .block--contact .h-headline {
        margin-bottom: 0; } }
  .block--contact .h-contact {
    font-size: 18px;
    font-size: 1rem;
    line-height: 1.27778;
    margin: 1.27778em 0; }
    .block--contact .h-contact a {
      text-align: center;
      text-decoration: none;
      display: inline-block;
      position: relative;
      width: 100%;
      background: #fff;
      padding: 1.27778em 0.11111em; }
      @media (min-width: 321px) {
        .block--contact .h-contact a {
          text-align: left;
          padding: 1.22222em; } }
  .block--contact .location p {
    margin: 0; }

.csstransforms .block--contact .h-contact a:after {
  content: '';
  speak: none;
  display: block;
  border: 1px solid #222;
  position: absolute;
  width: 200%;
  height: 200%;
  top: 0;
  left: 0;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -o-transform-origin: 0% 0%;
  transform-origin: 0% 0%; }
  @media (-moz-windows-theme) {
    .csstransforms .block--contact .h-contact a:after {
      display: none; } }

body .block--contact .block__grid {
  padding-bottom: 6.38889em; }
  @media all and (min-width: 575px) {
    body .block--contact .block__grid {
      padding-top: 1.27778em; } }

/* -------------------------------------------------------------- 

PROFILE + CONTACT

-------------------------------------------------------------- */
.block--project .h-headline, .block--project p, .block--project li {
  font-size: 18px;
  font-size: 1rem;
  line-height: 1.27778; }

.block--project p {
  margin-bottom: 1.27778em; }
  .block--project p:last-child {
    margin-bottom: 0; }

.block--project .detail {
  margin-bottom: 1.27778em; }

.block--project .description + .description {
  margin-top: 1.27778em; }
  @media all and (min-width: 1421px) {
    .block--project .description + .description {
      margin-top: 0; } }

.block--project .block--project__sub-nav {
  background: #e4e4e4;
  position: relative; }
  .block--project .block--project__sub-nav li {
    list-style: none;
    display: block;
    float: left;
    margin-right: .25em; }
    .block--project .block--project__sub-nav li:after {
      content: '/';
      margin-left: .25em; }
    .block--project .block--project__sub-nav li:last-child:after {
      display: none; }

.block--project__header {
  position: relative; }
  .block--project__header:after {
    content: '';
    display: table;
    clear: both; }

.block--project .h-rule {
  display: block;
  clear: both;
  position: relative;
  height: 1.27778em;
  margin-right: auto;
  margin-left: auto; }
  .block--project .h-rule:before {
    content: '';
    speak: none;
    display: block;
    border-top: 1px solid #222;
    position: absolute;
    height: 0;
    top: 0.61111em;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    -o-transform: scaleY(0.5);
    transform: scaleY(0.5); }
  @media all and (min-width: 575px) {
    .block--project .h-rule {
      display: none; } }
  @media all and (min-width: 575px) and (max-width: 960px) {
    .block--project .h-rule.s {
      display: block;
      max-width: 29.38889em; } }
  @media all and (min-width: 961px) and (max-width: 1420px) {
    .block--project .h-rule.m {
      display: block;
      max-width: 49.83333em; }
    .block--project .h-rule.m-half {
      display: block;
      clear: none;
      float: left;
      width: 24.27778em;
      margin-right: 0;
      margin-left: 1.27778em; } }
  @media all and (min-width: 1421px) {
    .block--project .h-rule.l {
      display: block;
      max-width: 75.38889em; }
    .block--project .h-rule.l-half {
      display: block;
      clear: none;
      float: left;
      width: 37.05556em;
      margin-right: 0;
      margin-left: 1.27778em; } }

.block--project__gallery {
  background: #e4e4e4;
  position: relative; }
  .block--project__gallery:after {
    content: '';
    display: table;
    clear: both; }
  @media all and (min-width: 961px) {
    .block--project__gallery .v-rule {
      content: '';
      speak: none;
      display: block;
      border-left: 1px solid #222;
      position: absolute;
      width: 0;
      top: 0;
      right: auto;
      bottom: 0;
      left: 50%;
      -webkit-transform: scaleX(0.5);
      -moz-transform: scaleX(0.5);
      -ms-transform: scaleX(0.5);
      -o-transform: scaleX(0.5);
      transform: scaleX(0.5); } }
  @media all and (min-width: 961px) {
    .block--project__gallery .v-rule.n2 {
      margin-left: 12.77778em; } }
  @media all and (min-width: 1421px) {
    .block--project__gallery .v-rule.n2 {
      margin-left: 19.16667em; } }
  .block--project__gallery .h-rule {
    background: #e4e4e4; }
  .block--project__gallery .img-wrap {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    z-index: 500; }
  .block--project__gallery .device .img-wrap {
    background: none;
    box-shadow: none; }
  .block--project__gallery .col.top {
    padding-top: 1.27778em; }
  @media all and (min-width: 575px) and (max-width: 960px) {
    .block--project__gallery .col.s-top {
      padding-top: 1.27778em; } }
  @media all and (min-width: 961px) and (max-width: 1420px) {
    .block--project__gallery .col.m-top {
      padding-top: 1.27778em; } }
  @media all and (min-width: 1421px) {
    .block--project__gallery .col.l-top {
      padding-top: 1.27778em; } }

.block--project__contact {
  background: #e4e4e4;
  padding-bottom: 6.38889em; }
  .block--project__contact .h-contact {
    font-size: 18px;
    font-size: 1rem;
    line-height: 1.27778;
    margin-bottom: 1.27778em; }
    .block--project__contact .h-contact a {
      color: #fff;
      text-align: center;
      text-decoration: none;
      background: #222;
      display: inline-block;
      position: relative;
      width: 100%;
      padding: 1.27778em 0.11111em; }
      @media (min-width: 321px) {
        .block--project__contact .h-contact a {
          text-align: left;
          padding: 1.22222em; } }
      @media all and (min-width: 961px) {
        .block--project__contact .h-contact a {
          width: auto; } }

/* DIY MAGAINE
-------------------------------------------------------------- */
@media all and (min-width: 961px) {
  .diy-magazine .block--project__gallery .v-rule.n2 {
    bottom: auto;
    height: 35.55556em; } }

@media all and (min-width: 1421px) {
  .diy-magazine .block--project__gallery .v-rule.n2 {
    bottom: auto;
    height: 53.27778em; } }
