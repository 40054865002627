/* -------------------------------------------------------------- 

SCSS EXTENSIONS

1. BASE FONT SIZE
2. RULES
3. UNIT CONVERTERS

--------------------------------------------------------------

1. BASE FONT SIZE
This is the pixel value but do not include ‘px’
-------------------------------------------------------------- */
$base: 18;


/* -------------------------------------------------------------- 

2. RULES
-------------------------------------------------------------- */
@mixin v-rule-before($color,$top,$right,$bottom,$left) {
	content: '';
	speak: none;
	display: block;
	border-left: 1px solid $color;
	position: absolute;
	width: 0;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;

	// TRANSFORM FOR HALF PIXEL
	-webkit-transform: scaleX(.5);
	-moz-transform: scaleX(.5);
	-ms-transform: scaleX(.5);
	-o-transform: scaleX(.5);
	transform: scaleX(.5);

}

@mixin h-rule-before($color,$top,$right,$bottom,$left) {
	content: '';
	speak: none;
	display: block;
	border-top: 1px solid $color;
	position: absolute;
	height: 0;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;

	// TRANSFORM FOR HALF PIXEL
	-webkit-transform: scaleY(.5);
	-moz-transform: scaleY(.5);
	-ms-transform: scaleY(.5);
	-o-transform: scaleY(.5);
	transform: scaleY(.5);

}


/* -------------------------------------------------------------- 

3. UNIT CONVERTERS

Guide to units: 
rem = font-sizes, use typeset() to set font-size and line-height
% = for responsive elements, use per() to calculate
px = media queries and border widths
em = for almost everything else; non-responsive widths, 
max-widths, padding, margins, use em() to calculate

-------------------------------------------------------------- */

/* PERCENTAGES
Example use: per(500, 1000)
would output: 50%
-------------------------------------------------------------- */
@function per($target,$context) {
	@return ($target/$context)*100+0%;
}

/* FONT SIZE AND LINE HEIGHT
Example use (if $base is set to 18): @include typeset(18,24);
would output: font-size: 18px; font-size: 1rem; line-height: 1.33333;
-------------------------------------------------------------- */
@mixin typeset($font-size, $line-height) {
	font-size: $font-size+0px;
	font-size: ($font-size/$base)+0rem;
	line-height: ($line-height/$font-size);
}

/* EMS
Example use: em(12, 24)
would output: 0.5em
-------------------------------------------------------------- */
@function em($target, $context) {
	@return ($target/$context)+0em;
}

/* LINE-HEIGHT - for when typeset is not appropriate
Example use: lh(24, 12)
would output: 2
-------------------------------------------------------------- */
@function lh($target, $context) {
	@return ($target/$context);
}

/* REMS - for where you might want to use rem as a unit for 
something other than font-size
Example use (if $base is set to 18): @include rem(margin,18 0);
would output: margin: 18px 0; margin: 1rem 0;
-------------------------------------------------------------- */
@mixin rem($property, $params) {
	$max: length($params);
	$px-values: '';
	$rem-values: '';

	@for $i from 1 through $max {
		$param: nth($params, $i);

		@if type-of($param) == number and unitless($param) and $param != 0 {
			$value: $param;
			$px-values: #{$px-values + $value}px;
			$rem-values: #{$rem-values + $value / $base}rem;
		} @else {
			$px-values: #{$px-values + $param};
			$rem-values: #{$rem-values + $param};
		}

		@if $i < $max {
			$px-values: #{$px-values + " "};
			$rem-values: #{$rem-values + " "};
		}

	}

	#{$property}: $px-values;
	#{$property}: $rem-values;

}