/* -------------------------------------------------------------- 

FONTS

For all font-families and weights

-------------------------------------------------------------- */
body {

	// text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;

	-moz-font-feature-settings:'liga' 1;
	-moz-font-feature-settings:'liga=1';
	-ms-font-feature-settings:'liga' 1;
	-o-font-feature-settings:'liga' 1;
	-webkit-font-feature-settings:'liga' 1;
	font-feature-settings:'liga' 1;

}

body,
input,
textarea {

	font-family: sans-serif;
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;

}

.sanomatSansTextRegularWeb {

	body,
	input,
	textarea {

		font-family: 'Sanomat Sans Text Regular Web', sans-serif;
		font-weight: 400;
		font-style: normal;
		font-stretch: normal;

	}

}

// .sanomatSansBoldWeb	{

	.h-section:before {

		font-family: 'Sanomat Sans Bold Web', sans-serif;
		font-weight: 700;
		font-style: normal;
		font-stretch: normal;

	}

// }

.sanomatSansTextRegularWeb {

	.oakbrook-finance .flip-date {

		font-family: 'Action Cond Medium Grade 2 Web', sans-serif;
		font-weight: 400;
		font-style: normal;
		font-stretch: normal;

	}

}

