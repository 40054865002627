/* -------------------------------------------------------------- 

FORMS

-------------------------------------------------------------- */

form { outline: 0; border: 0; }
form ol { list-style: none; }
input[type='text'],
input[type='submit'],
input[type='email'],
textarea {
	@include typeset(18,30);
	color: palette(grey,dark);
	background: $white;
	border: 1px solid palette(grey,x-light);
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0;
	width: 100%;
	&:hover, &:active, &:focus {
		border-color: palette(grey);
	}
}
input[type='text'],
input[type='submit'],
input[type='email'] {
	padding: em(10,18) em(15,18); 
	-webkit-appearance: none;
}
input[type='checkbox'] {
	-webkit-appearance: checkbox;
}

textarea { 
	padding: em(6,18) em(9,18); 
	-webkit-appearance: none; 
}

// Labels
label {
	@include typeset(18,30);
	display: block;
	margin: 0 0 em(6,18);
}

// Submit buttons
input[type='submit'].submit {
	width: auto;
	-webkit-appearance: none;
	&:hover, &:active, &:focus {
		cursor: pointer;
		// background-color: palette(grey,xx-light);
	}
}


/* MAILING LIST
-------------------------------------------------------------- */
.mailing-list {

	.mailing-list__inputs {
		overflow: hidden;
		&:hover input {
			border-color: palette(grey);
		}
	}

	.mailing-list__email {
		width: em(165,18);
		height: em(52,18);

		@include mq($from: m) {
			width: em(264,18);
		}

	}

	.mailing-list__submit {
		border-left: none;
		height: em(52,18);
		&:hover, &:active, &:focus {
			border-color: palette(grey,x-light);
		}
	}

	.btn-submit
	.mailing-list__email:focus + .mailing-list__submit{
		border-color: palette(grey);
	}

}



