/* -------------------------------------------------------------- 

FONT-FACE

-------------------------------------------------------------- */

@font-face {
	font-family: 'Sanomat Sans Bold Web';
	src: url('https://studiolindeman.com/ui/fonts/SanomatSans-Bold-Web.woff2') format('woff2'),
		url('https://studiolindeman.com/ui/fonts/SanomatSans-Bold-Web.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Sanomat Sans Text Regular Web';
	src: url('/ui/fonts/SanomatSansText-Regular-Web.woff2') format('woff2'),
		url('/ui/fonts/SanomatSansText-Regular-Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Action Cond Medium Grade 2 Web';
	src: url('/ui/fonts/ActionCondensedMedium-Grade2-Web.woff2') format('woff2'),
		url('/ui/fonts/ActionCondensedMedium-Grade2-Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}